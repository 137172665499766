import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './building-card.css';

const defaultImg = require('../../../shared/assets/imgs/building.jpg');

const BuildingCard = (props) => {
  const { item } = props;

  const [img, setImg] = useState(defaultImg);

  useEffect(() => {
    getImage();
  }, []);

  /**
   * Use Node.js function: ``` require() ``` to load external modules (files).
   * In this case, the static images resources.
   * 
   * @returns 
   */
  const getImage = () => {
    if (!item.imgSrc) return;

    try {
      const image = require(`../../../shared/assets/imgs/${item.imgSrc}`);
      setImg(image);
    } catch (error) {
      console.error(`Image: ${item.imgSrc} not found.`);
    }
  };

  const element = (
    <NavLink className="bimy-user-item" to={`/viewer/${item.id}`} target="_blank">
      <img className="ddc-card-img" src={img} alt="building" />
      <div className="ddc-card-content">
        <h4 className="ddc-building-card-title">{item.title}</h4>
        <div className="ddc-building-card-description">{item.description}</div>
      </div>
    </NavLink>
  );

  const card = <div className="ddc-building-card">{element}</div>;

  return card;
};

BuildingCard.propTypes = {
  item: PropTypes.any.isRequired,
};

export default BuildingCard;
