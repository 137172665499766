import React from 'react';

import './list.css';

/**
 *
 * @param {*} props
 * @returns
 */
const List = (props) => {
  return (
    <div
      className={`ddc-list list-group ${
        props.striped && `list-group-striped`
      } overflow-auto h-100 ${props.className}`}
      {...props}
      >
      {props.children}
    </div>
  );
};

export default List;
