import { request } from './http-client.service';

const PATH_DICTIONARY = '/dictionary';

export function getAllDictionary() {
  return request(`${PATH_DICTIONARY}`, 'GET', null, {
    'Content-Type': 'application/json',
  });
}

/**
 * This function requests to get all the Dictionary (from token).
 * 
 * @param {*} cbRequest 
 * @returns 
 */
export function getDictionary(cbRequest) {
  return cbRequest(`${PATH_DICTIONARY}`, 'GET', null , {
    'Content-Type': 'application/json'
  });
}