import { request } from './http-client.service';

const PATH_TYPE_ASSET = '/typeasset';

export function getTypeAssets() {
  return request(`${PATH_TYPE_ASSET}`, 'GET', null, {
    'Content-Type': 'application/json',
  });
}

export function getTypeAssetsByType(instanceId, typeId) {
  return request(`${PATH_TYPE_ASSET}/instance/${instanceId}/bytype/${typeId}`, 'GET', null, {
    'Content-Type': 'application/json',
  });
}

export function createTypeAsset(typeAsset) {
  return request(`${PATH_TYPE_ASSET}?setdefaults=true`, 'POST', JSON.stringify(typeAsset), {
    'Content-Type': 'application/json'
  });
}

export function upadateTypeAsset(typeAsset) {
  return request(`${PATH_TYPE_ASSET}?setdefaults=true`, 'PATCH', JSON.stringify(typeAsset), {
    'Content-Type': 'application/json'
  });
}

/**
 * 
 * @param {*} cbRequest
 * @param {*} items collection of Type Asset - Dictionary values
 * @param {*} instanceId if provided, then it populates the values to the POIs by IV instance
 * @param {*} typeAssetId
 * @returns 
 */
export function updateTypeAssetDictionary(cbRequest, items = [], instanceId = null, typeAssetId = null) {
  let queryParams;
  (instanceId && typeAssetId) && (queryParams = new URLSearchParams({instanceId, typeAssetId}).toString());

  const endpoint = `${PATH_TYPE_ASSET}/dictionary${queryParams ? '?'.concat(queryParams) : ''}`;

  return cbRequest(endpoint, 'PUT', JSON.stringify({items}) , {
    'Content-Type': 'application/json'
  });
}

/**
 * 
 * @param {*} typeAssetId 
 * @param {*} cbRequest 
 * @returns 
 */
export function getTypeAssetValues(typeAssetId, cbRequest) {
  const endpoint = `${PATH_TYPE_ASSET}/getvalues/${typeAssetId}`;
  return cbRequest(endpoint, 'GET', null, {
    'Content-Type': 'application/json',
  });
}

/**
 * 
 * @param {*} instanceId 
 * @param {*} typeId 
 * @param {*} cbRequest 
 * @returns 
 */
 export function getTypeAssetsByTypeId(instanceId, typeId, cbRequest) {
  const endpoint = `${PATH_TYPE_ASSET}/instance/${instanceId}/bytype/${typeId}`;
  return cbRequest(endpoint, 'GET', null , {
    'Content-Type': 'application/json'
  });
}