import React from 'react';

import './card.css';

const Card = (props) => {
  return (
    <div className={`ddc-card card mb-3 ${props.className && props.className}`}>
      <div className='ddc-card-img mx-auto mt-3'>
        {props.imgSrc && <img src={props.imgSrc} className="card-img-top" alt="..." />}
        {props.image && props.image}
      </div>
      <div className="card-body">
        {props.title && <h5 className="card-title text-center">{props.title}</h5>}
        {/* <p className="card-text">Some text</p> */}
        {props.children}
      </div>
    </div>
  );
};

export default Card;