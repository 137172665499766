import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Button from "../../ui-elements/button/button";
import Item from "../item/item";

import { Actions } from "../../../constants/actions";

import "./item-actions.css";

const ItemActions = (props) => {
  const { actions, item } = props;
  const [isSelect, setIsSelect] = useState(false);

  useEffect(() => {
    setIsSelect(props.selected);
  }, [props.selected]);

  const actionHandler = (action) => {
    // if the action is not specify, then do not do the callback
    if (!actions.includes(action)) return;

    // Only when Action SELECT exist can handle Select Style
    action == Actions.SELECT &&
      actions.includes(Actions.SELECT) &&
      setIsSelect(!isSelect);

    props.onAction(action, item);
  };

  return (
    <a
      href="#"
      className={`item-actions list-group-item list-group-item-action ${isSelect ? 'bg-info' : ''}`}
      aria-current="true">
      <div className="row justify-content-between  gx-0" onClick={() => actionHandler(Actions.SELECT)}>
        <Item className="col" onItemClick={() => actionHandler(Actions.VIEW)}>
          {props.children}
        </Item>
        {actions.includes(Actions.UPDATE, Actions.DELETE) && (
          <div className="col-1 text-end col-1 d-flex justify-content-center align-items-center">
          {actions.includes(Actions.DELETE) && (
            <Button
              className="btn-outline-danger my-1 bi bi-trash px-2"
              title="Delete"
              onClick={() => actionHandler(Actions.DELETE)}
            />
          )}
          {actions.includes(Actions.UPDATE) && (
            <Button
              className="btn-outline-success my-1 bi bi-pencil px-2"
              title="Edit"
              onClick={() => actionHandler(Actions.UPDATE)}
            />
          )}
        </div>
        )}
      </div>
    </a>
  );
};

ItemActions.propTypes = {
  actions: PropTypes.array.isRequired,
  onAction: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  selected: PropTypes.bool,
};

ItemActions.defaultProps = {
  selected: false,
};
export default ItemActions;
