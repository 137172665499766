import React from 'react';

import FieldForm from '../field-form/field-form';

import List from '../../../shared/components/ui-elements/list/list';
import Collapsible from '../../../shared/components/ui-elements/collapsible/collapsible';
import ItemActions from '../../../shared/components/ui-elements/item-actions/item-actions';
import './field-list.css';

const FieldList = (props) => {
  const items = props.items;
  const actionHandler = (action, field) => {
    props.onAction(action, field);
  };

  const renderCategory = (category, label) => {
    const catItems = items.filter((item) => item.category === category);

    // if (!catItems.length) return;

    const render = (
      <Collapsible
        title={label}
        isOpen={true}
        containe={
          <>
            {!catItems.length ? (
              <span>No items found</span>
            ) : (
              <List>
                {catItems.map((item) => (
                  <ItemActions
                    key={item.id}
                    item={item}
                    onAction={actionHandler}
                    actions={[]}>
                    <div className="d-flex w-100 justify-content-between">
                      <h5 className="mb-0">{item.description}</h5>
                    </div>
                    <small className="text-muted">{item.name}</small>
                    <FieldForm field={item} onFormSave={actionHandler} />
                  </ItemActions>
                ))}
              </List>
            )}
          </>
        }
      />
    );
    return render;
  };

  const content = (
    <div className="ddc-field-list h-100">
      {renderCategory('INFORMATION', 'Information')}
     {/* {renderCategory('CLASSIFICATION', 'Classification')}
      {renderCategory('MATERIAL', 'Material')}
      {renderCategory('REUSE_PROPERTY', 'Reuse')}
      {renderCategory('PHYSICAL_PROPERTY', 'Physical Properties')}
      {renderCategory('ELECTRICAL_PROPERTY', 'Electrical Properties')} */}
    </div>
  );

  return content;
};

export default FieldList;
