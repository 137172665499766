import React, { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import './tab.css';

/**
 *
 * @param {*} props
 *
 * · tabs ➡ Example:
 * ``` javascript
 * tabs = [{
 *    id: 'user-id',
 *    title: 'User tab',
 *    content: '<h1>List for user</h1>'
 * }]
 * ```
 * @returns
 */
const Tab = (props) => {
  const tabs = props.tabs;
  const openTabHandler = (event, id) => {
    // Declare all variables
    let i, tabcontent, tablinks;

    // Get all elements with class="ddc-tabcontent" and hide them
    tabcontent = document.getElementsByClassName('ddc-tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none';
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName('ddc-tablink');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(id).style.display = 'block';
    event.currentTarget.className += ' active';
  };

  const content = (
    <Fragment>
      <div className="ddc-tab p-2">
        {tabs.map((tab) => (
          // <button
          //   key={uuidv4()}
          //   className="ddc-tablink"
          //   onClick={(event) => openTabHandler(event, tab.id)}>
          //   {tab.title}
          // </button>
          <a
            href="#"
            className="ddc-tablink"
            key={uuidv4()}
            onClick={(event) => openTabHandler(event, tab.id)}>
            {tab.title}
          </a>
        ))}
      </div>
      {tabs.map((tab) => (
        <div key={uuidv4()} id={tab.id} className="ddc-tabcontent">
          {tab.content}
        </div>
      ))}
    </Fragment>
  );

  return content;
};

Tab.propsType = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ),
};

Tab.defaultProps = {
  tabs: [],
};

export default Tab;
