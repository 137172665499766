import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Card, Box, TextField, CardActions } from "@mui/material";
import { useForm } from "../../../shared/hooks/form.hook";
import Button from "../../../shared/components/ui-elements/button/button";
import { MuiTelInput } from "mui-tel-input";

import "./poi-booking.css";

/**
 * This is a form to handle POI's requests *
 * @param {*} props
 * @returns
 */
const PoiBooking = (props) => {
  const poi = props.poi;
  const defaultState = {
    fullname: {
      value: "",
      isValid: false,
    },
    email: {
      value: "",
      isValid: false,
    },
    telephone: {
      value: "",
      isValid: false,
    },
    comments: {
      value: "",
      isValid: false,
    },
    poiId: {
      value: poi.id,
      isValid: true,
    },
    instanceId: {
      value: props.instanceId,
      isValid: true,
    },
  };

  const [formState, inputChange, resetForm] = useForm(defaultState);

  const [phone, setPhone] = useState("+33");
  const [fullname, setFullname] = useState(null);
  const [email, setEmail] = useState(null);
  const [comments, setComments] = useState(" ");

  const handlePhoneNumber = (number) => {
    const formattedPhoneNumber = number.replace(/(\+\d+)\s/, "$1-");
    setPhone(formattedPhoneNumber);
  };

  const bookingHandler = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData(event.target);
      formData.append("fullname", (formState.inputs.fullname.value = fullname));
      formData.append("email", (formState.inputs.email.value = email));
      //formData.append('telephone', formState.inputs.telephone.value);
      formData.append("telephone", (formState.inputs.telephone.value = phone));
      formData.append("comments", (formState.inputs.comments.value = comments));
      formData.append("poiId", formState.inputs.poiId.value);
      formData.append("instanceId", formState.inputs.instanceId.value);

      // Parse FormData to JSON
      const data = {};
      formData.forEach((value, key) => (data[key] = value));

      // Callback function to the parent
       props.onBookingPoi(event, data);

      // Empty fields from form - DOES NOT work with custom Form elements
      //event.target.reset();
      resetForm();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form onSubmit={bookingHandler}>
      <Card sx={{ p: 3 }}>
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            marginBottom: 20,
          }}
        >
          <TextField
            id="poiName"
            label="Poi Name"
            name="poiname"
            disabled
            value={poi.title}
            inputProps={{ readOnly: true }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& > fieldset": { borderColor: "black" },
              },
            }}
          />
          <TextField
            id="fullname"
            required
            label="Your full name..."
            type="text"
            name="fullname"
            onChange={(e) => setFullname(e.target.value)}
          />
          <TextField
            id="email"
            required
            label="Your email address..."
            type="email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <MuiTelInput
            label="Telephone *"
            name="telephone"
            id="telephone"
            value={phone}
            onChange={handlePhoneNumber}
          />
        </Box>
        <TextField
          fullWidth
          id="comments"
          label="Comments"
          name="comments"
          onChange={(e) => setComments(e.target.value)}
          rows={10}
          multiline
          sx={{
            "& .MuiOutlinedInput-root": {
              "& > fieldset": { borderColor: "black" },
            },
          }}
        />
        <CardActions>
          <Button className="ddc-bim-blue ddc-w-100" type="submit">
            Book!
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

PoiBooking.propTypes = {
  poi: PropTypes.object.isRequired,
  instanceId: PropTypes.any.isRequired,
};

export default PoiBooking;
