import React from 'react';

import './item.css';

const Item = (props) => {

  const item = props.item;

  /**
   * This function event callbacks the item loaded for this component
   */
  const clickHandler = () => {
    props.onItemClick(item);
  };

  return (
    <div className={`ddc-item ${props.className}`} onClick={clickHandler}>
      {props.children}
    </div>
  );
};

export default Item;
