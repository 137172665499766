import { useCallback, useState } from 'react';
import { LevelStatus } from '../constants/LevelStatus';

export const useMessage = () => {
  const [message, setMessage] = useState();
  const [show, setShow] = useState(false);
  const [level, setLevel] = useState(LevelStatus.INFO);

  /**
   * This function handles when and how long to dsiplay messages via a Toast
   *
   * @param {String} message
   * @param {LevelStatus} level
   * @param {Number} timeout
   */
  const showMessage = useCallback(async (message, level = LevelStatus.INFO, timeout = 3000) => {
    console.log('showMessage...');
    setMessage(message);
    setShow(true);
    setLevel(level);
    setTimeout(() => {
      setShow(false);
      setMessage();
    }, timeout);
  });

  return [message, show, showMessage, level];
};
