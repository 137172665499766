import React, { useEffect, useRef, useReducer } from 'react';
import { validate } from '../../../utils/validators';

import './file-picker.css';

/**
 * This File Picker component can support multiple selection.
 *
 * So it will loop for every file and validate it.
 *
 * @param {*} files
 * @param {*} validators
 * @returns
 */
const validateFiles = (files, validators) => {
  for (let file of files) {
    if (!validate(file, validators)) return false;
  }
  return true;
};

const filesReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE':
      return {
        ...state,
        value: { ...action.val },
        isValid: validateFiles(action.val, action.validators),
      };
    default:
      return state;
  }
};

/**
 *
 * @see [How-to File Upload Component](https://dev.to/chandrapantachhetri/responsive-react-file-upload-component-with-drag-and-drop-4ef8)
 * @param {*} param0
 * @returns
 */
const FilePicker = ({
  id,
  label,
  filesPickerCB,
  validators,
  ...otherProps
}) => {
  const [filesState, dispatch] = useReducer(filesReducer, {
    value: {},
    isValid: false,
  });
  const fileInputField = useRef(null);

  const files = filesState.value;
  const isValid = filesState.isValid;

  // Any change in will trigger a Callback request to the parent.
  useEffect(() => {
    filesPickerCB(id, files, isValid);
    // TODO when picking, images show a preview (to add HTML section)
    // see API FileReader (chapter 162)
  }, [id, files, isValid, filesPickerCB]);

  const convertNestedObjectToArray = (nestedObj) =>
    Object.keys(nestedObj).map((key) => nestedObj[key]);

  const onClickHandler = () => {
    fileInputField.current.click();
  };

  const onChangeHandler = (event) => {
    const pickedFiles = event.target.files;

    dispatch({
      type: 'CHANGE',
      val: pickedFiles,
      validators: validators,
    });
  };

  return (
    <div className="bimy-form-control text-center file-input">
      <label className="form-label">{label}</label>
      <p>Drag and drop your files anywhere or</p>
      <div className="text-center">
        <button
          type="button"
          className="file-input__button"
          onClick={onClickHandler}>
          <i className="fas fa-file-upload file-input__ico text-center" />
          <span> Upload {otherProps.multiple ? 'files' : 'a file'}</span>
        </button>
      </div>
      <input
        className="file-input__input"
        type="file"
        id="file"
        title=""
        value=""
        ref={fileInputField}
        onChange={onChangeHandler}
        {...otherProps}
      />
    </div>
  );
};

export default FilePicker;
