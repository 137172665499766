import React from 'react';

import { Actions } from '../../../shared/constants/actions';

// import Input from '../../../shared/components/form-elements/input/input';
import Input from '../../../shared/components/ui-elements/input/input';

import './field-form.css';

const FieldForm = (props) => {
  const field = props.field;
  const changeHandler = async (id, value, isValid) => {
    if (field.value !== value) {
      field.value = value;
      props.onFormSave(Actions.UPDATE, field);
    }
  };

  return (
    <Input
      id={field.label}
      className="mb-3"
      value={field && field.value ? field.value : ''}
      validators={[]}
      onInputChanged={changeHandler}
    />
  );
};

export default FieldForm;
