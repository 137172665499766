import { useCallback, useReducer } from 'react';

import { FormActions } from '../constants/form-actions';

/**
 * It will first validate the form status and then save the info for the input.
 *
 * @param {*} state current values for the different inputs in the form
 * @param {*} action {type, value, isValid, inputId}
 */
const formReducer = (state, action) => {
  switch (action.type) {
    case FormActions.INPUT_CHANGED:
      let formIsValid = true;
      for (const inputId in state.inputs) {
        if (inputId === action.id) formIsValid = formIsValid && action.isValid;
        else formIsValid = formIsValid && state.inputs[inputId].isValid;
      }

      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.id]: {
            value: action.value,
            isValid: action.isValid,
          },
        },
        isFormValid: formIsValid,
      };
    case FormActions.RESET_FORM:
      return action.state;
    case FormActions.LOAD_STATE:
      return {
        ...state,
        inputs: action.inputs,
        isFormValid: action.isFormValid,
      };
    default:
      // If not action, keep the current values for this form hook
      return state;
  }
};

/**
 * This hook will handle a form for validating all the fields parsed.
 *
 * It will manage:
 * - inputs' data: []
 *
 * @param {*} initialInputs all the different input fields to be validated
 * @param {*} initialValidationStatus the initial status for the form
 * @returns [formState, inputChange, resetForm]
 * - **formState**: Current values for inputs for the form
 * - **inputChange** Event to validate when there is a change in one input
 * - **resetForm:** Set to the initial state the current form
 */
export const useForm = (initialInputs, initialValidationStatus = false) => {
  const initialState = {
    inputs: initialInputs,
    isFormValid: initialValidationStatus,
  };

  const [formState, dispatch] = useReducer(formReducer, {
    inputs: initialInputs,
    isFormValid: initialValidationStatus,
  });

  const loadState = useCallback(
    (newInputs, validationStatus = false) => {
      dispatch({
        type: FormActions.LOAD_STATE,
        inputs: newInputs,
        isFormValid: validationStatus,
      });
    }
  );

  const resetForm = useCallback(() => {
    dispatch({
      type: FormActions.RESET_FORM,
      state: initialState,
    });
  });

  /**
   * @param {*} id the identifier for the input element
   * @param {*} value the new value
   * @param {*} isValid the new value
   */
  const inputChange = useCallback((id, value, isValid) => {
    dispatch({
      type: FormActions.INPUT_CHANGED,
      id: id,
      value: value,
      isValid: isValid,
    });
  });

  return [formState, inputChange, resetForm, loadState];
};
