import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useHttpClient } from '../../shared/hooks/http.hook';
import { useMessage } from '../../shared/hooks/message.hook';

import { Actions } from '../../shared/constants/actions';

import BookingList from '../components/booking-list/booking-list';
import PoiBooking from '../../viewer/components/poi-booking/poi-booking';

import Card from '../../shared/components/ui-elements/card/card';
import Toast from '../../shared/components/ui-elements/toast/toast';
import Modal from '../../shared/components/ui-elements/modal/modal';
import Loader from '../../shared/components/ui-elements/loader/loader';
import Validator from '../../shared/components/ui-elements/validator/validator';

import { getPoi } from '../../services/iv.service';
import { generateToken } from '../../services/token.service';
import {
  bookPoi,
  findBookingByPoiCB,
} from '../../services/booking.service';

import './booking.css';

const Booking = (props) => {
  const { instanceId, poiId } = useParams();

  // Data
  const [poi, setPoi] = useState();
  const [bookingData, setBookingData] = useState();
  const [token, setToken] = useState();
  const [isModal, setIsModal] = useState(false);

  // Rendering
  const [modalLayout, setModalLayout] = useState({});
  const [content, setContent] = useState();

  // Hooks
  const [isLoading, error, sendRequest, clearError] = useHttpClient();
  const [message, show, showMessage] = useMessage();
  let navigate = useNavigate();

  useEffect(() => {
    if (instanceId && poiId) doPoi();
    else setContent(<BookingList />);
  }, []);

  useEffect(() => {
    poi &&
      setContent(
        <Card className="w-100" title={'Booking Asset'}>
          <div>
            <PoiBooking
              poi={poi}
              onBookingPoi={bookingPoiHandler}
              instanceId={instanceId}
            />
          </div>
        </Card>
      );
  }, [poi]);

  useEffect(() => {
    !!token && showTokenForm();
  }, [token]);

  /**
   * This function shows the modal.
   */
  const openModalHandler = () => setIsModal(true);

  /**
   * This fcunction resets modal content and close it.
   */
  const closeModalHandler = () => {
    setModalLayout({});
    setIsModal(false);
  };

  /**
   * This function will:
   * - Check if the POI is already booked.
   * -
   *
   * @returns
   */
  const doPoi = async () => {
    try {
      const bookingData = await findBookingByPoiCB(
        instanceId,
        poiId,
        sendRequest
      );

      if (bookingData.find((item) => item.status === Actions.BOOKED)) {
        showMessage('Asset already booked');
        setTimeout(function () {
          navigate(`/`);
        }, 3000);
        return;
      }

      const data = await getPoi(instanceId, poiId, sendRequest);
      if (data) {
        // Set Title (no provide from IV request)
        data.title = Object.values(data.titles)[0];
        setPoi(data);
      }
    } catch (error) {
      console.error(error);
      showMessage(error.message);
      setTimeout(function () {
        navigate(`/`);
      }, 3000);
    }
  };

  const showTokenForm = () => {
    const layout = {
      title: 'Verify email',
      content: <Validator onValidating={bookPoiHandler} />,
    };
    setModalLayout(layout);
    openModalHandler();
  };

  const bookPoiHandler = async (value) => {
    try {
      // Insert Token in FormData
      const formData = bookingData;
      formData.set('tokenkey', token);
      formData.set('tokenvalue', value);

      // Parse FormData to JSON
      const data = {};
      formData.forEach((value, key) => (data[key] = value));

      await bookPoi(data, sendRequest);
      closeModalHandler();
      showMessage('POI booked!');

      // Redirect to homepage after booking
      setTimeout(function () {
        navigate(`/`);
      }, 3000);
    } catch (error) {
      console.error(error);
      showMessage(error.message);
    }
  };

  /**
   * This function will:
   * - Close the Form to book a POI
   * - Store the Form's data
   * - Display and get a new token for this booking
   *
   * @param {*} event
   * @param {*} data
   */
  const bookingPoiHandler = async (event, data) => {
    try {
      const response = await generateToken(data.get('email'), sendRequest);
      const newToken = response.key;
      closeModalHandler();
      setBookingData(data);
      setToken(newToken);
    } catch (error) {
      setToken();
      setBookingData();
      showMessage(error.message);
    }
  };

  return (
    <Fragment>
      {content}

      <Modal
        display={isModal}
        onClose={closeModalHandler}
        title={modalLayout.title || ''}>
        {modalLayout.content || ''}
      </Modal>
      <Loader isDisplayed={isLoading} />
      <Toast isShow={show}>{message}</Toast>
    </Fragment>
  );
};

export default Booking;
