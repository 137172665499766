const REACT_APP_DDC_URI_API = process.env.REACT_APP_DDC_URI_API || 'https://reuse.bim-y.com/api';

/**
 * This function works as incoming access point for every request to the server.
 *
 * @param {*} path
 * @param {*} method The HTTP method used. By default: GET.
 * @param {*} headers
 * @param {*} body
 */
export async function request(path, method = 'GET', body = null, headers = {}) {
  const url = REACT_APP_DDC_URI_API + path;
  console.log('url -> ' + url);

  let response = null;
  try {
    response = await fetch(url, {
      method: method,
      headers: headers,
      body: body,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }

  return response;
}