import { request } from './http-client.service';

const PATH_IV_INSTANCE = '/instance';

export function getInstances() {
  return request(`${PATH_IV_INSTANCE}`, 'GET', null, {
    'Content-Type': 'application/json',
  });
}

/**
 * 
 * @param {*} requestCB 
 * @returns 
 */
export function getAllInstances(requestCB) {
  return requestCB(`${PATH_IV_INSTANCE}`, 'GET', null, {
    'Content-Type': 'application/json',
  });
}

export function getInstance(id) {
  return request(`${PATH_IV_INSTANCE}/${id}`, 'GET', null, {
    'Content-Type': 'application/json',
  });
}
