import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { bookPoi } from "../../../services/booking.service";
import { getAllDictionary } from "../../../services/dictionary.service";
import {
  getAllPoisTypes,
  saveDataPOI,
  createPOI,
} from "../../../services/iv.service";
import {
  getTypeAssetsByType,
  getTypeAssetValues,
} from "../../../services/type-asset.service";
import { findBookingByPoi } from "../../../services/booking.service";
import { generateToken } from "../../../services/token.service";

import { useMessage } from "../../../shared/hooks/message.hook";
import { useHttpClient } from "../../../shared/hooks/http.hook";
import { AuthContext } from "../../../shared/context/auth.context";
import { Actions } from "../../../shared/constants/actions";
import { VALIDATOR_MINLENGTH } from "../../../shared/utils/validators";
//import Input from '../../../shared/components/ui-elements/input/input';
import Card from "../../../shared/components/ui-elements/card/card";
import {
  Typography,
  Button,
  Stack,
  Box,
  Input,
  TextField,
  CardContent,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import BookmarkAddedOutlinedIcon from "@mui/icons-material/BookmarkAddedOutlined";
import Modal from "../../../shared/components/ui-elements/modal/modal";
import Toast from "../../../shared/components/ui-elements/toast/toast";
import Collapsible from "../../../shared/components/ui-elements/collapsible/collapsible";
import Loader from "../../../shared/components/ui-elements/loader/loader";
import Validator from "../../../shared/components/ui-elements/validator/validator";
import QRCode from "../../../shared/components/ui-elements/qr-code/qr-code";
import Table from "../../../shared/components/ui-elements/table/table";
//import Button from '../../../shared/components/ui-elements/button/button';
import Select from "../../../shared/components/form-elements/select/select";
import { styled } from "@mui/material/styles";
import PoiBooking from "../poi-booking/poi-booking";
import "./poi-info.css";

const { REACT_APP_DDC_URI_GUI } = process.env;

const PoiInfo = (props) => {
  const { instanceId, poi } = props;
  const navigate = useNavigate();
  const [message, show, showMessage] = useMessage();
  const [isLoading, error, sendRequest, clearError] = useHttpClient();
  const auth = useContext(AuthContext);
  const [modalLayout, setModalLayout] = useState({});
  const [isModal, setIsModal] = useState(false);
  const [bookingData, setBookingData] = useState();
  const [token, setToken] = useState();
  const [dictionary, setDictionary] = useState();
  const [types, setTypes] = useState([]);
  const [typeAssets, setTypeAssets] = useState([]);
  // POI Info (Type, TypeAsset & +++ustom Data)
  const [booking, setBooking] = useState();
  const [description, setDescription] = useState();
  const [type, setType] = useState();
  const [typeAsset, setTypeAsset] = useState();
  const [classifications, setClassifications] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [title, setTitle] = useState();
  const [uoId, setUoId] = useState();
  const [trademark, setTrademark] = useState();
  const [reuseProps, setReuseProps] = useState([]);
  const [physicalProps, setPhysicalProps] = useState([]);
  const [electricalProps, setElectricalProps] = useState([]);

  useEffect(() => {
    loadDictionary();
    loadTypes();
  }, []);

  useEffect(() => {
    poi && doPoi();
  }, [poi, dictionary, type, types, typeAssets]);

  useEffect(() => {
    loadTypeAssets();
  }, [type]);

  useEffect(() => {
    doTypeAsset();
  }, [typeAssets]);

  useEffect(() => {
    !!token && showTokenForm();
  }, [token]);

  const doPoi = async () => {
    // Only find booking if POI exists
    poi.id && findBooking();
    doType();
    doTypeAsset();
    doTitle();
    doUoId();
    doTrademark();
    doDescription();
    doClassifications();
    doMaterials();
    doPhysical();
    doElectrical();
    doReuse();
  };

  const initLoader = async () => {
    await loadDictionary();
    await loadTypes();
  };

  const findBooking = async () => {
    try {
      const response = await findBookingByPoi(instanceId, poi.id);

      if (response.status === 201) {
        const responseData = await response.json();
        const bookedBooking = responseData.find(
          (b) => b.status === Actions.BOOKED
        );
        setBooking(bookedBooking);
      } else {
        const error = await response.json();
        showMessage(error.message);
        setBooking();
      }
    } catch (error) {
      showMessage(error.message);
      setBooking();
    }
  };

  const populateTypeAsset = async (newTypeAsset) => {
    const values = await loadTypeAssetValues(newTypeAsset.id);
    console.table(values);
    doReuse(values);
    doTrademark(values.find((value) => value.label === "TRADEMARK"));
    doPhysical(values);
    doElectrical(values);
    doMaterials(values);
    doClassifications(values);
    setDescription(newTypeAsset.description ? newTypeAsset.description : "");
  };

  const loadTypeAssetValues = async (typeAssetId) => {
    let typeAssetsValues = [];
    try {
      typeAssetsValues = await getTypeAssetValues(typeAssetId, sendRequest);

      // const response = await getTypeAssetValues(typeAssetId, sendRequest);
      // if (response.status === 201) {
      //   const responseData = await response.json();
      //   typeAssetsValues = responseData;
      // } else {
      //   const error = await response.json();
      //   showMessage(error.message);
      // }
    } catch (error) {
      showMessage(error.message);
    }

    return typeAssetsValues;
  };

  const loadTypes = async () => {
    try {
      const response = await getAllPoisTypes(instanceId);
      if (response.status === 201) {
        const responseData = await response.json();
        // Add property title to each type to be used on Select component
        const customTypes = responseData.map((item) => ({
          ...item,
          title: item.name["en"] || item.name[Object.keys(item.name)[0]],
        }));
        setTypes(customTypes);
      } else {
        setTypes([]);
        console.log("getting types failed!");
        const error = await response.json();
        showMessage(error.message);
      }
    } catch (error) {
      showMessage(error.message);
    }
  };

  const loadTypeAssets = async () => {
    if (!instanceId || !type) return;

    try {
      const response = await getTypeAssetsByType(instanceId, type.id);

      if (response.status === 201) {
        const responseData = await response.json();
        const customTypeAssets = responseData.map((item) => ({
          ...item,
          title: item.name,
        }));
        setTypeAssets(customTypeAssets);
      } else if (response.status === 204) {
        // Not found | HTTP Error code: 204
        setTypeAssets([]);
        showMessage(`No Type Assets found for type: ${type.title}`);
      } else {
        setTypeAssets([]);
        console.error("getting Type Assets failed!");
        const error = await response.json();
        showMessage(error.message);
      }
    } catch (error) {
      showMessage(error.message);
    }
  };

  const loadDictionary = async () => {
    try {
      const response = await getAllDictionary();

      if (response.status === 201) {
        const responseData = await response.json();
        setDictionary(responseData);
      } else {
        setDictionary([]);
        const error = await response.json();
        showMessage(error.message);
      }
    } catch (error) {
      showMessage(error.message);
    }
  };

  /**
   *
   * @param {*} defaults
   */
  const doClassifications = (defaults = []) => {
    const data = poi.customData && JSON.parse(poi.customData);
    let arrayClassesProps = [];
    if (data && data.classifications) {
      // Load data from POI
      arrayClassesProps = data.classifications.map((c) => c);
    } else if (data && data.typeAssetId) {
      // TODO Load defaults from DB
    } else if (dictionary) {
      // Load empty from DB
      const dictClassifications = dictionary.filter(
        (d) => d.category === "CLASSIFICATION"
      );
      arrayClassesProps = dictClassifications.map((c) => [c.name, ""]);
    }

    setClassifications(arrayClassesProps);
  };

  /**
   *
   * @param {*} defaults
   */
  const doMaterials = (defaults = []) => {
    const icon = <i className={`fa-regular fa-square-caret-up`}></i>;
    const data = poi.customData && JSON.parse(poi.customData);
    let arrayMaterials = [];
    if (data && data.materials && !defaults.length) {
      // Load data from POI
      const materialsCustom = [...data.materials];
      // arrayMaterials = materialsCustom.map((m) => {
      //   m.unshift(icon);
      //   return m;
      // });
      arrayMaterials = materialsCustom.map((m) => m);
      // } else if (data && data.typeAssetId) {
      //   // TODO Load defaults from DB
    } else if (dictionary) {
      // Load empty from DB
      const dictMaterials = dictionary.filter((d) => d.category === "MATERIAL");
      arrayMaterials = dictMaterials.map((m) => [
        // icon,
        m.name,
        defaults.find((item) => item.name === m.name)
          ? defaults.find((item) => item.name === m.name).value
          : "",
        "",
      ]);
    }
    setMaterials(arrayMaterials);
  };

  /**
   *
   * @param {*} defaults
   */
  const doPhysical = (defaults = []) => {
    const data = poi.customData && JSON.parse(poi.customData);
    let arrayPhysicalProps = [];
    if (data && data.physicalProps && !defaults.length) {
      // Load data from POI
      arrayPhysicalProps = data.physicalProps.map((p) => p);
      // } else if (data && data.typeAssetId) {
      //   // Load defaults from DB
      //   const tav = loadTypeAssetValues(data.typeAssetId);
      //   arrayPhysicalProps = tav.map((p) => [p.name, p.value, p.unit]);
    } else if (dictionary) {
      // Load empty from DB
      const dictPhysical = dictionary.filter(
        (d) => d.category === "PHYSICAL_PROPERTY"
      );
      arrayPhysicalProps = dictPhysical.map((dp) => [
        dp.name,
        defaults.find((item) => item.name === dp.name)
          ? defaults.find((item) => item.name === dp.name).value
          : "",
        "",
      ]);
    }
    setPhysicalProps(arrayPhysicalProps);
  };

  const doElectrical = (defaults = []) => {
    const data = poi.customData && JSON.parse(poi.customData);
    let arrayElectricalProps = [];
    if (data && data.electricalProps && !defaults.length) {
      // Load data from POI
      arrayElectricalProps = data.electricalProps.map((p) => p);
      // } else if (data && data.typeAssetId) {
      //   // Load defaults from DB
      //   const tav = loadTypeAssetValues(data.typeAssetId);
      //   arrayPhysicalProps = tav.map((p) => [p.name, p.value, p.unit]);
    } else if (dictionary) {
      // Load empty from DB
      const dictElectrical = dictionary.filter(
        (d) => d.category === "ELECTRICAL_PROPERTY"
      );
      arrayElectricalProps = dictElectrical.map((dp) => [
        dp.name,
        defaults.find((item) => item.name === dp.name)
          ? defaults.find((item) => item.name === dp.name).value
          : "",
        "",
      ]);
    }
    setElectricalProps(arrayElectricalProps);
  };

  /**
   *
   * @param {*} defaults
   */
  const doReuse = (defaults = []) => {
    const data = poi.customData && JSON.parse(poi.customData);
    let arrayReuseProps = [];
    if (data && data.reuseProps && !defaults.length) {
      // Load data from POI
      arrayReuseProps = data.reuseProps.map((p) => p);
    } else if (dictionary) {
      // Load empty from DB
      const dictReuse = dictionary.filter(
        (d) => d.category === "REUSE_PROPERTY"
      );
      arrayReuseProps = dictReuse.map((dp) => [
        dp.name,
        defaults.find((item) => item.name === dp.name)
          ? defaults.find((item) => item.name === dp.name).value
          : "",
      ]);
    }
    setReuseProps(arrayReuseProps);
  };

  const doDescription = () => {
    setDescription(poi.description ? poi.description : "");
  };

  const doTitle = () => {
    setTitle(poi.title ? poi.title : "");
  };

  const doUoId = () => {
    if (poi && poi.customData) {
      const data = JSON.parse(poi.customData);
      const uoId = data.uoId;
      setUoId(uoId && uoId);
    }
  };

  const doTrademark = (value = undefined) => {
    if (poi && poi.customData && !value) {
      const data = JSON.parse(poi.customData);
      const trademark = data.trademark;
      setTrademark(trademark ? trademark : "");
    } else if (value) setTrademark(value.value);
  };

  const doType = () => {
    if (!types.length) return;

    // By default, pick the first Type
    let selectedType = types[0];

    // If the POI has an existing POI type, then set it
    if (poi && poi.poiType.id) {
      const filter = types.filter((t) => t.id === poi.poiType.id);
      filter.length && (selectedType = filter[0]);
    }

    selectedType && setType(selectedType);
  };

  const doTypeAsset = () => {
    if (!typeAssets.length) {
      setTypeAsset();
      return;
    }

    // By default, pick the first Type Asset
    let selectedTypeAsset;
    // let selectedTypeAsset = typeAssets[0];

    // If the POI has an existing POI type, then set it
    let typeAssetId;

    let filter = [];
    if (poi && poi.customData) {
      const data = JSON.parse(poi.customData);
      typeAssetId = data.typeAssetId;

      typeAssetId &&
        (selectedTypeAsset = typeAssets.find((ta) => ta.id == typeAssetId));
      // filter.length && (selectedTypeAsset = filter[0]);
    }

    setTypeAsset(selectedTypeAsset);
  };

  const savePOIHandler = async () => {
    if (!poi) return;
    // Since the items in the array are referenced, it is required to force a copy for each into a new array
    // const materialsCustom = materials.map((m) => m.slice());
    const data = {
      customData: {
        ...(poi.customData && JSON.parse(poi.customData)),
        typeAssetId: typeAsset && typeAsset.id,
        physicalProps,
        electricalProps,
        reuseProps,
        classifications,
        // materials: materialsCustom.map((m) => {
        //   // Remove icon
        //   m.shift();
        //   return m;
        // }),
        materials,
        // trademark: trademark && trademark.value,
        trademark: trademark,
      },
      description: description,
      poiTypeId: type.id,
    };

    try {
      let response;
      // When existing POI
      if (poi.id) response = await saveDataPOI(instanceId, poi.id, data);
      else {
        // When new POI, add Title
        if (!title || !title.value.trim().length)
          throw new Error("Please, specify the POI name");
        // Save Unique Object ID only if added
        if (uoId && uoId.value.length) data.customData.uoId = uoId.value;
        data.title = title.value;
        data.orientation = poi.orientation;
        data.location = poi.globalLocation;
        response = await createPOI(instanceId, data);
      }

      if (response.status === 201) {
        const responseData = await response.json();
        console.log("repsonseData", responseData);
        // props.onSaved && props.onSaved(responseData.poi);
        navigate(`/viewer/${instanceId}/poi/${responseData.poi.id}`);
        showMessage(`POI ${poi.title} was saved.`);
      } else {
        console.error("Saving POI failed!");
        const error = await response.json();
        showMessage(error.message);
      }
    } catch (error) {
      console.error(error);
      showMessage(error.message);
    }
  };

  /**
   * This function shows the modal.
   */
  const openModalHandler = () => setIsModal(true);

  /**
   * This fcunction resets modal content and close it.
   */
  const closeModalHandler = () => {
    setModalLayout({});
    setIsModal(false);
  };

  /**
   * This function will:
   * - Close the Form to book a POI
   * - Store the Form's data
   * - Display and get a new token for this booking
   *
   * @param {*} event
   * @param {*} data
   */
  const bookingPoiHandler = async (event, data) => {
    try {
      //const token = await generateToken(data.get('email'), sendRequest);
      const token = await generateToken(data.email, sendRequest);

      closeModalHandler();
      setBookingData(data);
      setToken(token.key);
    } catch (error) {
      console.error(error);
      showMessage(error.message);
    }
  };

  const titleHandler = (id, value, isValid) => setTitle({ id, value, isValid });

  const uoIdHandler = (id, value, isValid) => setUoId({ id, value, isValid });

  // const trademarkHandler = (id, value, isValid) => setTrademark({ id, value, isValid });
  const trademarkHandler = (event) => {
    setTrademark(event.target.value);
  };

  const changeMaterialsHandler = (data) => setMaterials(data);

  const changeClassesHandler = (data) => setClassifications(data);

  const changeReuseHandler = (data) => setReuseProps(data);

  const changePhysicalHandler = (data) => setPhysicalProps(data);

  const changeElectricalHandler = (data) => setElectricalProps(data);

  const descriptionHandler = (event) => setDescription(event.target.value);

  /**
   * This function will store the key per value entered.
   *
   * @param {*} value
   */
  const bookPoiHandler = async (value) => {
    try {
      // Insert Token in FormData
      bookingData.tokenkey = token;
      bookingData.tokenvalue = value;
      const data = bookingData;

      await bookPoi(data, sendRequest);
      showMessage("POI booked!");
      closeModalHandler();
    } catch (error) {
      console.error(error);
      showMessage(error.message);
    }
  };

  const typeHandler = (id, value, isValid) => {
    poi && value && (poi.poiType.id = value.id);
    value && setType(value);
    // poi && loadTypeAssets();
  };

  const typeAssetHandler = (id, value, isValid) => {
    poi && value && (poi.poiType.id = value.id);
    value && setTypeAsset(value);
    value && populateTypeAsset(value);
  };

  const showBookingForm = () => {
    const layout = {
      title: "Booking ".concat(
        poi.poiType.name ? Object.values(poi.poiType.name)[0] : "Unknown"
      ),
      content: (
        <PoiBooking
          poi={poi}
          onBookingPoi={bookingPoiHandler}
          instanceId={instanceId}
        />
      ),
    };
    setModalLayout(layout);
    openModalHandler();
  };

  const showTokenForm = () => {
    const layout = {
      title: "Verify email",
      content: <Validator onValidating={bookPoiHandler} />,
    };
    setModalLayout(layout);
    openModalHandler();
  };

  const poiInfo = poi ? (
    <Card
      className="w-100"
      /* image={
        poi &&
        poi.id && (
          <QRCode
            value={`${REACT_APP_DDC_URI_GUI}/viewer/${instanceId}/poi/${poi.id}`}
          />
        )
      } 
      title={poi ?  poi.title : 'No title'} */
      title={poi ? "Information" : "No title"}
    >
     {/* <Box fontSize="15px" style={{ maxHeight: "40vh", overflow: "auto" }}> */}
     <CardContent fontSize="15px">
        {!poi.id && (
          <Input
            id="uoid"
            label="Unique Object ID"
            placeholder="The Unique Object ID..."
            type="text"
            name="uoid"
            value={""}
            validators={[VALIDATOR_MINLENGTH(2)]}
            onInputChanged={uoIdHandler}
          />
        )}
        <Stack textAlign={"left"}>
          <Select
            id="type"
            disabled={!auth.isLoggedIn}
            onChange={typeHandler}
            items={types}
            label="Type"
            validators={[]}
            defaultValue={type}
          />
          <Select
            id="typeAsset"
            disabled={!auth.isLoggedIn}
            onChange={typeAssetHandler}
            items={typeAssets}
            label="Type of Assets"
            validators={[]}
            defaultValue={typeAsset}
          />
        </Stack>
        <br />
        <Stack textAlign={"left"} spacing={2}>
          <TextField
            label="Trademark"
            value={trademark}
            InputLabelProps={{ shrink: true }}
            sx={{ fontSize: 15 }}
            onChange={trademarkHandler}
            name="trademark"
            id="trademark"
            multiline
            disabled={!auth.isLoggedIn}
          />
          <TextField
            label="Description"
            value={description}
            InputLabelProps={{ shrink: true }}
            sx={{ fontSize: 15 }}
            onChange={descriptionHandler}
            name="description"
            id="description"
            disabled={!auth.isLoggedIn}
            multiline
          />
        </Stack>
        {/* <Collapsible
          title="Information"
          isOpen={true}
          containe={<>
            {!poi.id && (
              <Input
                id="uoid"
                label="Unique Object ID"
                placeholder="The Unique Object ID..."
                type="text"
                name="uoid"
                value={''}
                validators={[VALIDATOR_MINLENGTH(2)]}
                onInputChanged={uoIdHandler}
              />
            )}
            <Stack textAlign={'left'}  >
              <Select
                id="type"
                disabled={!auth.isLoggedIn}
                onChange={typeHandler}
                items={types}
                label="Type"
                validators={[]}
                defaultValue={type}
              />
              <Select
                id="typeAsset"
                disabled={!auth.isLoggedIn}
                onChange={typeAssetHandler}
                items={typeAssets}
                label="Type of Assets"
                validators={[]}
                defaultValue={typeAsset}
              />
            </Stack><br />
            <Stack textAlign={'left'} spacing={2}>
              <TextField
                label='Trademark'
                value={trademark}
                InputLabelProps={{ shrink: true }}
                sx={{ fontSize: 15 }}
                onChange={trademarkHandler}
                name="trademark"
                id="trademark"
                multiline
                disabled={!auth.isLoggedIn}
              />
              <TextField
                label='Description'
                value={description}
                InputLabelProps={{ shrink: true }}
                sx={{ fontSize: 15 }}
                onChange={descriptionHandler}
                name="description"
                id="description"
                disabled={!auth.isLoggedIn}
                multiline
              />
            </Stack>
          </>}
        />
        <Collapsible
          title="Classifications"
          isOpen={false}
          containe={<>
            <div className="ddc-poi-info-classifications">
              <Box fontSize='12px'>
                <Table
                  columns={['Name', 'Code']}
                  data={classifications}
                  onChangeValue={changeClassesHandler}
                  editColumns={!auth.isLoggedIn ? [] : [1]}
                />
              </Box>
            </div>
          </>} />
        <Collapsible
          title="Imbedded Materials"
          isOpen={false}
          containe={<>
            <div className="ddc-poi-info-materials">
              <Box fontSize='12px'>
                <Table
                  // columns={['Hazzard', 'Name', 'Mass', 'Volume']}
                  columns={['Name', 'Mass', 'Volume']}
                  data={materials}
                  onChangeValue={changeMaterialsHandler}
                  // editColumns={[2, 3]}
                  editColumns={!auth.isLoggedIn ? [] : [1, 2]}
                />
              </Box>
            </div>
          </>} />
        <Collapsible
          title="Reuse Properties"
          isOpen={false}
          containe={<>
            <div className="ddc-poi-info-resue">
              <Box fontSize='12px'>
                <Table
                  data={reuseProps}
                  onChangeValue={changeReuseHandler}
                  editColumns={!auth.isLoggedIn ? [] : [1]}
                />
              </Box>
            </div>
          </>} />
        <Collapsible
          title="Physical Properties"
          isOpen={false}
          containe={<>
            <div className="ddc-poi-info-physical">
              <Box fontSize='12px'>
                <Table
                  columns={['Name', 'Value', 'Unit']}
                  data={physicalProps}
                  onChangeValue={changePhysicalHandler}
                  editColumns={!auth.isLoggedIn ? [] : [1, 2]}
                />
              </Box>
            </div>
          </>} />
        <Collapsible
          title="Electrical Properties"
          isOpen={false}
          containe={<>
            <div className="ddc-poi-info-electrical">
              <Box fontSize='12px'>
                <Table
                  columns={['Name', 'Value', 'Unit']}
                  data={electricalProps}
                  onChangeValue={changeElectricalHandler}
                  editColumns={!auth.isLoggedIn ? [] : [1, 2]}
                />
              </Box>
            </div>
          </>} /> */}
      </CardContent>
      <div className="col-12 mx-auto my-2 row text-center">
        {(!booking || booking.length === 0) && poi.id && (
          <Box className={`${auth.isLoggedIn ? "col-6" : "col-12"}`}>
            <Button
              className="ddc-btn-blue"
              style={{ borderRadius: 13, fontSize: 15 }}
              variant="contained"
              startIcon={<BookmarkAddedOutlinedIcon />}
              onClick={showBookingForm}
            >
              Book it!
            </Button>
          </Box>
        )}
        {auth.isLoggedIn && (
          <Box
            className={`${
              !booking || booking.length === 0 ? "col-6" : "col-12"
            }`}
          >
            <Button
              className="ddc-btn-blue"
              style={{ borderRadius: 13, fontSize: 15 }}
              onClick={savePOIHandler}
              variant="contained"
              startIcon={<SaveOutlinedIcon />}
              disabled={!poi && !poi.id && !title.isValid}
            >
              Save POI
            </Button>
          </Box>
        )}
      </div>
    </Card>
  ) : (
    <Typography variant="h5"> Please, select any POI. </Typography>
  );
  return (
    <>
      {poiInfo}
      <Modal
        display={isModal}
        onClose={closeModalHandler}
        title={modalLayout.title || ""}
      >
        {modalLayout.content || ""}
      </Modal>
      <Toast isShow={show}>{message}</Toast>
      <Loader isDisplayed={isLoading} />
    </>
  );
};

PoiInfo.propTypes = {
  poi: PropTypes.object.isRequired,
  instanceId: PropTypes.any.isRequired,
};

export default PoiInfo;
