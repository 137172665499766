import React, { useState, useEffect } from 'react';

import { getBookings, updateBooking } from '../../../services/booking.service';

import { Actions } from '../../../shared/constants/actions';

import { useMessage } from '../../../shared/hooks/message.hook';

import Loader from '../../../shared/components/ui-elements/loader/loader';
import Toast from '../../../shared/components/ui-elements/toast/toast';
import Table from '../../../shared/components/ui-elements/table/table';
import Button from '../../../shared/components/ui-elements/button/button';
import Modal from '../../../shared/components/ui-elements/modal/modal';
import Label from '../../../shared/components/ui-elements/label/label';
import MainContent from '../../../shared/components/ui-elements/main-content/main-content';
import { IconButton, Popover, MenuItem } from '@mui/material';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';

import './booking-list.css';

const { REACT_APP_DDC_URI_GUI } = process.env;

const BookingList = (props) => {
  // Modal
  const [modalLayout, setModalLayout] = useState({});
  const [isModal, setIsModal] = useState(false);
  // Loader
  const [loading, isLoading] = useState(false);
  // Message
  const [message, show, showMessage] = useMessage();
  const [bookings, setBookings] = useState([]);
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    console.log('sfsdf', event)
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    loadBookings();
  }, []);
  /**
   * This function shows the modal.
   */
  const openModalHandler = () => setIsModal(true);

  /**
   * This fcunction resets modal content and close it.
   */
  const closeModalHandler = () => {
    setModalLayout({});
    setIsModal(false);
  };

  const columns = [
    'PERSON',
    'EMAIL',
    'TELEPHONE',
    'WHERE',
    'POI',
    'STATUS',
    '',
  ];

  const updateBookingStatusHandler = async (booking, newStatus) => {
    isLoading(true);
    try {
      const bookingUpdated = booking;
      bookingUpdated.status = newStatus;
      const response = await updateBooking(bookingUpdated);
      if (response.status === 201) {
        showMessage('Booking updated');
        loadBookings();
        closeModalHandler();
      } else {
        const error = await response.json();
        showMessage(error.message);
      }
    } catch (error) {
      console.error(error.message);
      showMessage(error.message);
    }
    isLoading(false);
  }

  const confirmUpdateBookingStatusHandler = (booking, newStatus) => {
    console.log('confirmUpdateBookingStatusHandler...');

    let layout;

    switch (newStatus) {
      case Actions.CLOSED:
        layout = {
          title: `Poi ${booking.poiId}`,
          content: (
            <div>
              <p>Please, confirm you want to close this booking (By closing it, it will not be longer possible to book it).</p>
              <Button className="ddc-w-100" onClick={() => updateBookingStatusHandler(booking, newStatus)}>
                Close
              </Button>
            </div>
          ),
        };
        break;
      case Actions.CANCELLED:
        layout = {
          title: `Poi ${booking.poiId}`,
          content: (
            <div>
              <p>Please, confirm you want to cancel this booking (By cancelling it, it will be possible to book it again).</p>
              <Button className="ddc-w-100" onClick={() => updateBookingStatusHandler(booking, newStatus)}>
                Cancel
              </Button>
            </div>
          ),
        };
        break;
      default:
        layout = {
          title: `Poi ${booking.poiId}`,
          content: (
            <div>
              <p>You have selected the POI: {booking.poiId}</p>
              <Button className="ddc-w-100" onClick={closeModalHandler}>
                Close
              </Button>
            </div>
          ),
        };
        break;
    }

    setModalLayout(layout);
    openModalHandler();
  };

  const getlevel = (booking) => {
    let level = 'general';
    switch (booking.status) {
      case Actions.BOOKED:
        level = 'info';
        break;
      case Actions.CLOSED:
        level = 'success';
        break;
      case Actions.CANCELLED:
        level = 'danger';
        break;
    }
    return level;
  }

  const renderBookings = (bookingsData) => {
    const arrayBookings = bookingsData.map((booking) => [
      booking.fullname,
      booking.email,
      booking.telephone,
      booking.instance,
      <a
        href={REACT_APP_DDC_URI_GUI.concat(
          '/viewer/',
          booking.instanceId,
          '/poi/',
          booking.poiId
        )}>
        {booking.poiId}
      </a>,
      <div style={{ textAlign: 'center' }}>
        <Label value={booking.status} level={getlevel(booking)} />
      </div>,
      booking.status === Actions.BOOKED ? (
        <div style={{ textAlign: 'center' }}>
          <Button
            className="btn-success my-1 bi bi-check px-2"
            title="Close"
            onClick={() =>
              confirmUpdateBookingStatusHandler(booking, Actions.CLOSED)
            }
          />
          <Button
            className="btn-warning my-1 bi bi bi-x-circle px-2"
            title="Cancel"
            onClick={() =>
              confirmUpdateBookingStatusHandler(booking, Actions.CANCELLED)
            }
          />
        </div>
      ) : ''
    ]);
    return arrayBookings;
  }
  const loadBookings = async () => {
    isLoading(true);
    try {
      const response = await getBookings();
      if (response.status === 201) {
        const responseData = await response.json();
        const arrayBookings = renderBookings(responseData);
        setBookings(arrayBookings);
      } else {
        const error = await response.json();
        showMessage(error.message);
      }
    } catch (error) {
      console.error(error.message);
      showMessage(error.message);
    }
    isLoading(false);
  };

  return (
    <MainContent>
      <div className="ddc-booking">
        <Table columns={columns} data={bookings} />
      </div>
      <Modal
        display={isModal}
        onClose={closeModalHandler}
        title={modalLayout.title || ''}>
        {modalLayout.content || ''}
      </Modal>
      <Loader isDisplayed={loading} />
      <Toast isShow={show}>{message}</Toast>
    </MainContent>
  );
};

export default BookingList;