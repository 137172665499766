import React from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';

import './modal.css';

const ModalOverlay = (props) => {
  const isDisplayed = props.display;

  // CSS Property - display
  let display = 'none';
  isDisplayed ? (display = 'block') : (display = 'none');

  const closeHandler = () => {
    props.onClose();
  };

  let action;
  props.action && (
    action = <button
    type="button"
    className="btn btn-secondary"
    onClick={closeHandler}>
    Close
  </button>
  );

  const content = (
    <div id="ddcModal" className="ddc-modal" style={{ display: display }}>
      <div className="ddc-modal_content">
        <span className="ddc-modal_close" onClick={closeHandler}>
          &times;
        </span>
        <div className="modal-header">
          <h3 className="modal-title">&nbsp;{props.title && props.title }</h3>
        </div>

        <div className="modal-body">
        {props.children}
        </div>
        {/* <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            onClick={closeHandler}>
            Close
          </button>
          {props.action && action} 
        </div>*/}
      </div>
    </div>
  );
  return ReactDom.createPortal(content, document.getElementById('modal-hook'));
};

const Modal = (props) => {
  return <ModalOverlay {...props}>{props.children}</ModalOverlay>;
};

Modal.propTypes = {
  title: PropTypes.string,
  display: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default Modal;
