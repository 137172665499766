import React from 'react';

import './main-content.css';

const MainContent = (props) => {
  return (
    <main className={`ddc-main-content p-1 ${props.className ? props.className : ''}`}>{props.children}</main>
  );
};

export default MainContent;