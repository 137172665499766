import { Box, Avatar, IconButton, Popover } from '@mui/material';
import { useState, useContext } from 'react';
import { alpha } from '@mui/material/styles';
import Login from './login'
import IMAGES from '../../../shared/assets/imgs/images';
import { AuthContext } from '../../../shared/context/auth.context';

export default function AccountPopover() {
    const [open, setOpen] = useState(null);
    const auth = useContext(AuthContext);

    const handleOpen = (event) => {
     setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    return (
        <>
            <IconButton
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                      '&:before': {
                        zIndex: 1,
                        content: "''",
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        position: 'absolute',
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                      },
                    }),
                  }}
            >
             {auth.isLoggedIn ?  <Avatar src={IMAGES.avatar} alt="photoURL" /> :
             <Avatar src={''} alt="photoURL" />}
            </IconButton>
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                sx: {
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    width: 180,
                    '& .MuiMenuItem-root': {
                    typography: 'body2',
                    borderRadius: 3.75,
                    },
                },
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Login />
                </Box>
            </Popover>
        </>
    )
}