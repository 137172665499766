import React from 'react';
import PropTypes from 'prop-types';

import './button.css';

const Button = (props) => {
  const clickHandler = () => {
    props.onClick && props.onClick();
  } 

  return (
    <button
      className={`ddc-btn ${props.className ? props.className : 'ddc-btn-black'}`}
      onClick={clickHandler}
      disabled={props.disabled}
      title={props.title}
      type={props.type}>
      {props.children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset'])
}

Button.defaultProps = {
  type: 'button'
}

export default Button;
