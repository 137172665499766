import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './select.css';

const Select = (props) => {
  const [itemSelected, setItemSelected] = useState();
  const items = props.items;

  /**
   *
   * @param {*} event
   */
  const changeHandler = (event) => {
    const selection = items.find((i) => i.id == event.target.value);
    
    setItemSelected(selection);
  };

  const clickHandler = (event) => {
      props.onConfirm(itemSelected);
  };

  let element = (
    <div className='row gy-2 gx-3 align-items-center'>
      <div className='col-12 col-md-9'>
          <select
            className="form-select"
            aria-label="select"
            onChange={changeHandler}>
              <option defaultValue>
                Please, select one
              </option>
            {items.map((item) => (
              <option key={item.id} value={item.id}>
                {item.title}
              </option>
            ))}
          </select>
      </div>
      <div className='col-12 col-md-3'>
          <button
            type="button"
            className={`btn ${itemSelected && 'btn-primary'} ${!itemSelected && 'btn-secondary'}`}
            onClick={clickHandler}
            disabled={!itemSelected && true}>
            Confirm
          </button>
      </div>
    </div>
  );

  return <div>{element}</div>;
};

Select.propTypes = {
  items: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default Select;
