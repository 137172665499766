import React, { useState, useRef, useContext} from 'react';
import { useForm } from '../../../shared/hooks/form.hook';
import { useMessage } from '../../../shared/hooks/message.hook';
import { VALIDATOR_EMAIL } from '../../../shared/utils/validators';
import { doSignIn } from '../../../services/auth.service';
import { AuthContext } from '../../context/auth.context';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography, Divider, Stack, Chip  } from '@mui/material';
import Button from '../ui-elements/button/button';
import Input from '../ui-elements/input/input';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import Snackbar from '@mui/material/Snackbar';

const defaultState = {
    email: {
      value: '',
      isValid: false,
    },
    password: {
      value: '',
      isValid: true,
    },
  };

const Login = (props) => {
  const auth = useContext(AuthContext);
  const emailRef = useRef();
  const passwordRef = useRef();
  const { isConnected } = props;
  const [loading, isLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState(false);
  const [message, show, showMessage] = useMessage();
  const [formState, inputChange, resetForm] = useForm(defaultState);

  const doLogin = async (event) => {
    event.preventDefault();
    isLoading(true);
    try {
      const formData = new FormData(event.target);
      formData.append('email', formState.inputs.email.value);
      formData.append('password', formState.inputs.password.value);

      // Parse FormData to JSON
      const data = {};
      formData.forEach((value, key) => (data[key] = value));

      const response = await doSignIn(data);
      if (response.status === 201) {
        const responseData = await response.json();
        // Add credentials to memory
        auth.login(responseData.user.id, responseData.token);
        setOpen(true)
        setSnackMessage('Vous etes connecté !!!')
        // Empty fields from form
        doReset();
      } else {
        const error = await response.json();
        showMessage(error.message);
        setOpen(true)
        setSnackMessage('Veuillez vérifier vos identifiants')
      }
    } catch (err) {
      console.error(err);
      setOpen(true)
      setSnackMessage('Veuillez vérifier vos identifiants')
    }
    isLoading(false);
  };

  const doReset = () => {
    // Reset Form Hook
    resetForm();
    if (auth.isLoggedIn) {
      // Reset Child Components
      emailRef.current.resetComponent();
      passwordRef.current.resetComponent();
    }
  };

  return (
    <Box textAlign={'center'}>
        {!auth.isLoggedIn ? 
            <>
            <Typography variant="h6" noWrap>
              Connexion          
            </Typography>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <Stack fontSize={10} sx={{ p: 1 }}>
                <form className="form-horizontal" onSubmit={doLogin}>
                    <Input
                        id="email"
                        className="mb-3"
                        placeholder="Email"
                        type="email"
                        name="email"
                        validators={[VALIDATOR_EMAIL()]}
                        onInputChanged={inputChange}
                        ref={emailRef}/>
                    <Input
                        id="password"
                        className="mb-3"
                        placeholder="Password"
                        type="password"
                        name="password"
                        validators={[]}
                        onInputChanged={inputChange}
                        ref={passwordRef}/>
                    <Button
                        className="ddc-bim-blue"
                        type="submit"
                        disabled={!formState.isFormValid}>
                        Login
                    </Button>
                </form>
            </Stack>
            </>    
            : 
            <>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <Stack>
                <Chip 
                    label="Logout" 
                    onClick={auth.logout} 
                    sx={{ backgroundColor: 'white', color: '#F1A1A1' }}  
                    icon={<LogoutRoundedIcon sx={{ color: '#F1A1A1' }}/>}/>
                </Stack>
            </>
            }
            {loading && (
                <CircularProgress
                    size={24}
                    sx={{
                    color: 'blue',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                    }}
                />
            )}
        <Snackbar
            open={open}
            autoHideDuration={6000}
            message={snackMessage}
        />
    </Box>
  )

};
export default Login;