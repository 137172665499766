import React, { useCallback, useEffect, useState } from "react";

import { useMessage } from "../../shared/hooks/message.hook";
import { useHttpClient } from "../../shared/hooks/http.hook";
import { Box } from "@mui/material";

import { Actions } from "../../shared/constants/actions";
import { VALIDATOR_NOT_UNDEFINED } from "../../shared/utils/validators";

import Toast from "../../shared/components/ui-elements/toast/toast";
import Modal from "../../shared/components/ui-elements/modal/modal";
import Loader from "../../shared/components/ui-elements/loader/loader";
import Button from "../../shared/components/ui-elements/button/button";
import Checkbox from "../../shared/components/ui-elements/checkbox/checkbox";
import MainContent from "../../shared/components/ui-elements/main-content/main-content";

import Select from "../../shared/components/form-elements/select/select";

import FieldList from "../components/field-list/field-list";
import TypeAssetList from "../components/type-asset-list/type-asset-list";
import TypeAssetForm from "../components/type-asset-form/type-asset-form";

import { getInstances } from "../../services/instance.service";
import { getAllPoisTypes } from "../../services/iv.service";
import {
  getTypeAssetsByType,
  createTypeAsset,
  getTypeAssetValues,
  updateTypeAssetDictionary,
} from "../../services/type-asset.service";

import "./type-asset.css";

const TypeAsset = (props) => {
  const [loading, setIsLoading] = useState(false);
  const [instances, setInstances] = useState([]);
  const [instance, setInstance] = useState();
  const [types, setTypes] = useState([]);
  const [type, setType] = useState();
  const [selectedTypeAsset, setSelectedTypeAsset] = useState();
  const [typeAssets, setTypeAssets] = useState([]);
  const [typeAsset, setTypeAsset] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [isPopulated, setIsPopulated] = useState(false);
  const [typeAssetValues, setTypeAssetValues] = useState([]);

  const [message, show, showMessage] = useMessage();
  const [isLoading, error, sendRequest, clearError] = useHttpClient();

  // Manage Modal
  const [isModal, setIsModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const loadInstances = async () => {
    setInstance();
    try {
      const response = await getInstances();

      if (response.status === 201) {
        const responseData = await response.json();
        setInstances(responseData);
      } else {
        console.log("getting instances failed!");
        const error = await response.json();
        throw error;
      }
    } catch (error) {
      throw error;
    }
  };

  const loadTypeAssetValues = async () => {
    setIsLoading(true);
    if (!instance || !type) {
      setIsLoading(false);
      return;
    }
    try {
      const values = await getTypeAssetValues(typeAsset.id, sendRequest);
      setTypeAssetValues(values);
    } catch (error) {
      showMessage(error.message);
    }
    setIsLoading(false);
  };

  const loadTypeAssets = async () => {
    setTypeAsset();
    setIsLoading(true);
    if (!instance || !type) {
      setIsLoading(false);
      return;
    }
    try {
      const response = await getTypeAssetsByType(instance.id, type.id);

      if (response.status === 201) {
        const responseData = await response.json();
        setTypeAssets(responseData);
      } else if (response.status === 204) {
        // Not found | HTTP Error code: 204
        setTypeAssets([]);
        showMessage(`No Type Assets found for type: ${type.title}`);
      } else {
        setTypeAssets([]);
        console.log("getting Type Assets failed!");
        const error = await response.json();
        showMessage(error.message);
      }
    } catch (error) {
      showMessage(error.message);
    }
    setIsLoading(false);
  };

  const loadTypes = async () => {
    setIsLoading(true);

    // Clear any previous state
    setType();
    setTypeAsset();
    if (!instance) {
      setIsLoading(false);
      return;
    }
    try {
      const response = await getAllPoisTypes(instance.id);
      if (response.status === 201) {
        const responseData = await response.json();
        // Add property title to each type to be used on Select component
        const customTypes = responseData.map((item) => ({
          ...item,
          title: item.name["en"] || item.name[Object.keys(item.name)[0]],
        }));
        setTypes(customTypes);
      } else {
        setTypes();
        console.log("getting types failed!");
        const error = await response.json();
        showMessage(error.message);
      }
    } catch (error) {
      showMessage(error.message);
    }
    setIsLoading(false);
  };

  const doPopulateData = (isChecked) => setIsPopulated(isChecked);

  const doUpdateTypeAssetDictionary = async (event) => {
    // No values to be updated
    if (!typeAssetValues.length) return;

    setIsLoading(true);

    try {
      isPopulated && instance
        ? await updateTypeAssetDictionary(
            sendRequest,
            typeAssetValues,
            instance.id,
            typeAsset.id
          )
        : await updateTypeAssetDictionary(sendRequest, typeAssetValues);
      showMessage("Type Asset updated!");
    } catch (error) {
      showMessage(error.message);
    }
    setIsLoading(false);
  };

  const doSaveTypeAsset = async (typeAsset) => {
    setIsLoading(true);

    if (!typeAsset) {
      setIsLoading(false);
      return;
    }
    const response = await createTypeAsset(typeAsset);
    if (response.status === 201) {
      loadTypeAssets();
      hideModal();
      showMessage(`The Type Asset: ${typeAsset.name} was saved.`);
    } else {
      console.error("Saving Type Asset failed!");
      const error = await response.json();
      showMessage(error.message);
    }
    setIsLoading(false);
  };

  const showModal = () => {
    setIsModal(true);
  };

  const hideModal = () => {
    setModalContent("");
    setIsModal(false);
  };

  const typeAssetFieldHandler = (action, typeAssetField) => {
    switch (action) {
      case Actions.UPDATE:
        const updatedValues = typeAssetValues.map((item) => {
          if (item.id === typeAssetField.if) return typeAssetField;
          return item;
        });
        // doUpdateTypeAssetField(typeAssetField);
        setTypeAssetValues(updatedValues);
        setIsUpdated(true);
        break;
      default:
        break;
    }
  };

  const typeAssetActionHandler = (action, typeAsset) => {
    switch (action) {
      case Actions.ADD:
        const newTypeAsset = { ivTypeId: type.id, instanceId: instance.id };
        setModalContent(
          <TypeAssetForm
            className="modal-body"
            typeAsset={newTypeAsset}
            onFormSave={doSaveTypeAsset}
          />
        );
        showModal();
        break;
      case Actions.VIEW:
        console.log("fsdf", typeAsset);
        setSelectedTypeAsset(typeAsset.id)
        setTypeAsset(typeAsset);
        break;
      case Actions.UPDATE:
        setTypeAsset(typeAsset);
        break;
      default:
        break;
    }
  };

  const initLoader = () => {
    setIsLoading(true);
    try {
      loadInstances();
    } catch (error) {
      showMessage(error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    initLoader();
  }, []);

  useEffect(() => {
    setIsUpdated(false);

    if (instance && type && typeAsset) {
      loadTypeAssetValues();
      return;
    }

    // Only if instance and type exist, it can load the Type of Assets
    if (instance && type) {
      loadTypeAssets();
      return;
    }
    // When the instance exists and changes, load the types
    if (instance) {
      loadTypes();
    }

    if (!instance) {
      setType();
      setTypeAsset();
    }
  }, [instance, type, typeAsset]);

  const instanceHandler = useCallback((id, value, isValid) => {
    setTypeAsset();
    setType();
    setInstance(value);
  }, []);

  const typeHandler = useCallback((id, value, isValid) => {
    setTypeAsset();
    setType(value);
  }, []);

  const instanceSelect = (
    <Select
      id="instance"
      onChange={instanceHandler}
      items={instances}
      label="Select an instance:"
      validators={[VALIDATOR_NOT_UNDEFINED()]}
    />
  );

  const typeSelect = (
    <Select
      id="type"
      onChange={typeHandler}
      items={types ? types : []}
      label="Select a Type:"
      validators={[VALIDATOR_NOT_UNDEFINED()]}
    />
  );

  return (
    <MainContent>
      <div className="ddc-type-asset h-100">
        <div className="ddc-type-asset-selector row h-25">
          {instanceSelect}
          {instance && typeSelect}
        </div>
        <div className="ddc-type-asset-viewer row h-75 mt-2">
          <div className="ddc-pn-type-asset col-12 col-md-6 h-100">
            {type && (
              <div className="p-3 border bg-light h-100 overflow-auto">
                <TypeAssetList
                  items={typeAssets}
                  onAction={typeAssetActionHandler}
                  loadType={loadTypeAssets}
                  isLoading={setIsLoading}
                />
              </div>
            )}
          </div>
          <div className="ddc-pn-type-asset-value col-12 col-md-6 h-100">
            {typeAsset && (
              <div className="p-3 border bg-light h-100 overflow-auto">
                <Box style={{ maxHeight: "50vh", overflow: "auto" }}>
                  <FieldList
                    items={typeAssetValues}
                    onAction={typeAssetFieldHandler}
                  />
                </Box>

                <div className="row">
                  <div className="col-md-3 align-middle d-grid gap-1 mb-1 d-flex justify-content-center align-items-center">
                    <Checkbox
                      id="populateData"
                      name="populateData"
                      value="Populate data"
                      onClick={doPopulateData}
                    />
                  </div>
                  <div className="col-md-9 align-middle d-grid gap-1 mb-1">
                    <Button
                      className="ddc-w-100 ddc-bim-blue"
                      disabled={!isUpdated}
                      onClick={doUpdateTypeAssetDictionary}
                    >
                      <i className="bi bi-save d-block mx-auto mb-1 text-center"></i>
                      <small>Update</small>
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal display={isModal} onClose={hideModal} title="Type Asset">
        {modalContent}
      </Modal>
      <Loader isDisplayed={loading} />
      <Toast isShow={show}>{message}</Toast>
    </MainContent>
  );
};

export default TypeAsset;
