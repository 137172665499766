import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './checkbox.css';

/**
 * This reusabe component brings a HTML component
 * 
 * @param {*} props 
 * @returns 
 */
const Checkbox = (props) => {
  const { id, name, value } = props;

  /**
   * This function handles if the Checkbox is **checked** or not.
   * 
   * @param {*} event 
   */
  const clickHandler = (event) => {
    console.log('status', event.currentTarget.checked);
    props.onClick && props.onClick(event.currentTarget.checked);
  }

  return (
    <Fragment>
      <input type="checkbox" id={id} name={name} value={value} onClick={clickHandler}/>
      <label htmlFor={name}>{value}</label>
    </Fragment>
  );
};

Checkbox.propTypes = {
  id: PropTypes.any.isRequired,
  name: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default Checkbox;
