import { Fragment } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import Homepage from './homepage/container/homepage';
import Building from './building/container/building';
import Admin from './admin/container/admin';
import Viewer from './viewer/container/viewer';
import Booking from './booking/container/booking';

import MainNavigation from './shared/components/navigation/main-navigation/main-navigation';
import Footer from './shared/components/navigation/footer/footer';
import FooterV2 from './shared/components/navigation/footer-v2/footer-v2';

import { AuthContext } from './shared/context/auth.context';
import { useAuth } from './shared/hooks/auth.hook';

import './App.css';

function App() {
  const { token, login, logout, userId } = useAuth();

  let element = (
    <Fragment>
      <BrowserRouter>
        <MainNavigation />
        <Routes>
          <Route path="/" element={<Homepage />} exact />
          <Route path="/homepage" element={<Homepage />} exact />
          <Route path="/building" element={<Building />} />
          <Route path="/viewer/:instanceId/poi/:poiId" element={<Viewer />} />
          <Route path="/viewer/:instanceId" element={<Viewer />} />
          <Route path="/booking/:instanceId/:poiId" element={<Booking />} />
          {token && <Route path="/admin" element={<Admin />} />}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        {/* <Footer /> */}
        <FooterV2 />
      </BrowserRouter>
    </Fragment>
  );

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout,
      }}>
      {element}
    </AuthContext.Provider>
  );
}

export default App;
