import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';

import { LevelStatus } from '../../../constants/LevelStatus';

import './toast.css';

const ToastOverlay = (props) => {
  const { isShow, level } = props;
  const [levelClass, setLevelClass] = useState();

  useEffect(() => {
    switch (level) {
      case LevelStatus.ERROR:
        setLevelClass('ddc-error');
        break;
      case LevelStatus.SUCCESS:
        setLevelClass('ddc-success');
        break;
      case LevelStatus.WARNING:
        setLevelClass('ddc-warning');
        break;
      case LevelStatus.INFO:
      default:
        setLevelClass('ddc-info');
        break;
    }
  }, [level]);

  const content = (
    <div
      className={`ddc-toast
                  ${props.className && props.className}
                  ${isShow ? 'show' : ''}
                  ${levelClass}
                 `}>
      {props.children}
    </div>
  );
  return ReactDom.createPortal(content, document.getElementById('toast-hook'));
};

/**
 * This react component is designed to be display on top.
 *
 * By default, is always hide (_isShow = false_)
 *
 * The best practice to use it along the adding the component is
 * setting a timeout which updates the schedule how long the Toast
 * will be displayed.
 *
 * ```javascript
 * setIsShow(true);
 * setTimeout(() => { setIsShow(false) }, 3000);
 * ```
 *
 * @param {*} props
 * @returns
 */
const Toast = (props) => {
  return <ToastOverlay {...props}>{props.children}</ToastOverlay>;
};

Toast.propTypes = {
  isShow: PropTypes.bool.isRequired,
  level: PropTypes.any,
};

Toast.defaultProps = {
  isShow: false,
  level: LevelStatus.INFO,
};

export default Toast;
