import React, { Fragment } from 'react';

import IMAGES from '../../shared/assets/imgs/images';

import FooterV2 from '../../shared/components/navigation/footer-v2/footer-v2';
import MainContent from '../../shared/components/ui-elements/main-content/main-content';

import './homepage.css';

const Homepage = (props) => {
  return (
    <Fragment>
      <MainContent>
        <div className="ddc-homepage">
          <div className="ddc-homepage-header">
            <h1 className="ddc-homepage-title">
              Re:USE <span>by BIM-Y</span>
            </h1>
            <h3 className="ddc-homepage-quote">
              La plateforme du réemploi au Luxembourg
            </h3>
          </div>
          <div className="ddc-homepage-body m-4">
            <div className="ddc-homepage-who row">
              <div className="col-12 col-lg-5">
                <img
                  className="ddc-homepage-image"
                  src={IMAGES.linhPham}
                  alt="Pham"
                />
                <figcaption>Photo by Linh Pham - Upsplash</figcaption>
              </div>
              <div className="col-12 col-lg-7 ddc-text-quote">
                <p className="ddc-text-centered">
                  Si comme nous, vous pensez qu’il est temps de <b>réduire votre
                    empreinte carbone</b>,<br></br>utilisez la 1ère plateforme luxembourgeoise
                  dédiée au <b>réemploi de matériaux de construction</b>
                </p>
                <p className="">
                  « Au Luxembourg, la quantité des déchets générée par les
                  activités du domaine de la construction, qui incluent les
                  activités de déconstruction / démolition ne cesse d’augmenter et
                  s’élevait à 9 millions de tonnes en 2020. »<br></br><i>Guide de la
                    déconstruction</i>
                </p>
                <p>
                  <b>Re:USE</b> a pour ambition de réduire considérablement cette
                  quantité de déchets en s’inspirant des principes de l’économie
                  circulaire et en proposant de réserver les matériaux et
                  équipements issus de bâtiments qui seront démolis en vue de leur
                  réemploi dans d’autres bâtiments/projets architecturaux.
                </p>
                <p>
                  <b>Comment ça marche ?</b>
                </p>
                <ol>
                  <li>
                    Dans la barre de menu, cliquez sur "Buildings" puis
                    sélectionnez le bâtiment que vous souhaitez visiter
                  </li>
                  <li>
                    Déambulez librement dans le bâtiment pour découvrir l'objet
                    que vous cherchez ou utilisez la Recherche
                  </li>
                  <li>
                    Cliquez sur l'icone de l'objet pour obtenir plus
                    d'information sur ce dernier
                  </li>
                  <li>
                    Si vous avez trouver votre bonheur, cliquez sur le bouton
                    'Book it" et suivez la procédure de réservation
                  </li>
                  <li>
                    Au moment de la déconstruction vous serez recontacté pour
                    finaliser la transaction
                  </li>
                </ol>
              </div>
            </div>
            <div className="ddc-homepage-contact ddc-text-centered ddc-homepage-contact">
              <p>
                <b>Une question ? Besoin d'information ? Un bâtiment à déconstruire ?&nbsp;</b>
                Contactez-nous : <a href="mailto:team@bim-y.com">team@bim-y.com</a>
              </p>
              <br></br>
              <p>
                Pour tout demandes d'informations supplémentaires sur des produits, veuillez prendre contact avec les acteurs concernés via la plateforme en ligne ou via l'adresse email: <a href="mailto:team@bim-y.com"> <b>projet.euroffice-reuse@schroeder.lu</b></a>
              </p>
            </div>
          </div>
        </div>
      </MainContent>
    </Fragment>
  );
};

export default Homepage;
