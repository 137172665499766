export default {
    logoColor: require('./BIM-Y_logo_color.png'),
    logoWhite: require('./BIM-Y_logo_white.png'),
    logoBlack: require('./BIM-Y_logo_black.png'),
    building: require('./building.jpg'),
    defaultImage: require('./default-image.jpeg'),
    linhPham: require('./linh-pham.webp'),
    logoFonds: require('./logo_FondsCompensation.png'),
    logoList: require('./Logo_List.png'),
    logoSchroeder: require('./schroeder-logo.svg'),
    avatar: require('./avatar1.jpg'),
    logoBaatz: require('./baatz.png'),
    logoEntrapaulus: require('./entrapaulus.png')
  };
  