import React, { useState, Fragment } from 'react';

import Button from '../button/button';
import Input from '../input/input';

import './validator.css';

const Validator = (props) => {
  const defaultToken = '';
  const [token, setToken] = useState(defaultToken);
  const confirmTokenHandler = (event) => {
    event.preventDefault();
    props.onValidating(token);
  };

  const changedHandler = (id, value, isValid) => {
    setToken(value);
  };

  return (
    <Fragment>
      <form action="" onSubmit={confirmTokenHandler}>
        <Input
          id="token"
          className="ddc-text-center"
          label="Review your inbox folder with the email address provided to get the token."
          placeholder="Please, enter the code here"
          type="text"
          name="token"
          onInputChanged={changedHandler}/>
        <Button className="ddc-w-100" type="submit">
          Confirm
        </Button>
      </form>
    </Fragment>
  );
};

export default Validator;
