import React from 'react';
import { Link } from 'react-router-dom';

import Login from '../../ui-elements/login/login';
import MainHeader from '../main-header/main-header';
import NavLinks from '../nav-links/nav-links';
import Header from '../../header/index';

import IMAGES from '../../../../shared/assets/imgs/images';

import './main-navigation.css';

const MainNavigation = (props) => {
  let element = <div>logo!!</div>;

  element = (
    <nav className="ddc-main-nav navbar navbar-expand-lg navbar-light bg-light m-0">
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#navbarToggler"
          aria-controls="navbarToggler"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <Link className="navbar-brand" to="/homepage">
          <img
            className="ddc-header-logo"
            src={IMAGES.logoBlack}
            alt="BIM-Y logo"
          />
        </Link>
        <div
          className="offcanvas offcanvas-start"
          data-bs-scroll="true"
          tabIndex="-1"
          id="navbarToggler"
          aria-labelledby="offcanvasWithBothOptionsLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
              Digital Deconstruction
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"/>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <NavLinks />
            </ul>
            {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0"> */}
            <ul className="nav navbar-nav navbar-right">
              <Login />
            </ul>
          </div>
          <div className="offcanvas-footer"></div>
        </div>
      </div>
    </nav>
  );

  return (
    <>
      <Header />
       {/**<MainHeader>{element}</MainHeader>*/}
    </>
  );
};

export default MainNavigation;
