import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import PoiInfo from '../components/poi-info/poi-info';
import IvionView from "../components/indoor-viewer/ivionView"
import Toast from '../../shared/components/ui-elements/toast/toast';
import { useMessage } from '../../shared/hooks/message.hook';
import { getInstance } from '../../services/instance.service';
import MainContent from '../../shared/components/ui-elements/main-content/main-content'
import './viewer.css';

const Viewer = (props) => {
  const [poi, setPoi] = useState();
  const [instance, setInstance] = useState();
  const { instanceId, poiId } = useParams();
  // Message
  const [message, show, showMessage] = useMessage();

  useEffect(() => {
    instanceId && getInstanceById(instanceId);
  }, []);

  const getInstanceById = async (id) => {
    const response = await getInstance(id);
    if (response.status === 201) {
      const responseData = await response.json();
      setInstance(responseData);
    } else {
      console.error('getting buildings failed!');
      const error = await response.json();
      showMessage(error.message);
    }
  };

  const poiClickHandler = useCallback((newPoi) => {
    setPoi(newPoi);
  }, []);

  const newPoiHandler = useCallback((newPoi) => {
    setPoi(newPoi);
  }, []);

  return (
    <>
      <MainContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8} sx={{ height: 'calc(100vh - 292px)', maxHeight: 'calc(100vh - 292px)' }}>
            {instance && (
              <IvionView
                instanceid={instanceId}
                siteId={instance.url}
                poiId={poiId}
                onPoiClick={poiClickHandler}
                onNewPoi={newPoiHandler} />
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ height: '100%', maxHeight: '100%' }}>
            {!poi ? <Typography variant="h6"> Please, select any POI. </Typography> :
              <PoiInfo poi={poi} instanceId={instanceId} siteId={instance.url} />}
          </Grid>
        </Grid>
        <Toast isShow={show}>{message}</Toast>
      </MainContent>
    </>
  )
}
export default Viewer;