import React, { useEffect, useReducer, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import { validate } from '../../../utils/validators';

import './input.css';

const Actions = Object.freeze({
  CHANGED: 'CHANGED',
  INITIALISE: 'INITIALISE',
});

const inputReducer = (state, action) => {
  switch (action.type) {
    case Actions.CHANGED:
    case Actions.INITIALISE:
      return {
        ...state,
        value: action.newValue,
        isValid: validate(action.newValue, action.validators),
      };
    default:
      // Current data for the input
      return state;
  }
};

const Input = forwardRef((props, ref) => {
  const initialState = {
    value: props.value && props.value,
    isValid: false,
  };

  const [inputState, dispatch] = useReducer(inputReducer, initialState);
  
  useEffect(()=> {
    dispatch({
      type: Actions.CHANGED,
      newValue: props.value,
      validators: props.validators,
    });
  }, [props.value]);

  const id = props.id;
  const { value, isValid } = inputState;

  const type = props.type && props.type;

  useImperativeHandle(ref, () => ({
    resetComponent
  }));

  const resetComponent = () => {
    dispatch({
      type: Actions.INITIALISE,
      newValue: initialState.value,
      validators: props.validators,
    });
  }

  const changeHandler = (event) => {
    dispatch({
      type: Actions.CHANGED,
      newValue: event.target.value,
      validators: props.validators,
    });
  };

  useEffect(() => {
    props.value && dispatch({
      type: Actions.INITIALISE,
      newValue: props.value,
      validators: props.validators,
    });
  }, [])

  useEffect(() => {
    props.onInputChanged && props.onInputChanged(id, value, isValid);
  }, [value, isValid])

  let contentType;

  switch (type) {
    case 'textarea':
      contentType = (
        <textarea
          className="ddc-input"
          id={props.id}
          name={props.name && props.name}
          placeholder={props.placeholder ? props.placeholder : ''}
          style={{ height: '200px' }}
          onChange={changeHandler}
          value={value}>
        </textarea>
      );
      break;
    case 'text':
    default:
      contentType = (
        <input
          className={`ddc-input ${props.className && props.className}` }
          type={type}
          id={props.id}
          name={props.name && props.name}
          placeholder={props.placeholder && props.placeholder}
          disabled={props.disabled && props.disabled}
          value={value}
          pattern={props.pattern && props.pattern}
          onChange={changeHandler}
        />
      );
      break;
  }

  return (
    <div className="row">
      {props.label && (
        <div className={`col-12 d-flex align-items-center`}>
          <label htmlFor={props.id}>{props.label}{props.isMandatory && !isValid && <sup> *mandatory</sup>}</label>
        </div>
      )}
      <div className={`col-12`}>{contentType}</div>
    </div>
  );
});

Input.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  onInputChanged: PropTypes.func
};

Input.defaultProps = {
  validators: [],
  type: 'text',
  value: '',
};

export default Input;
