import React from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';

import './loader.css';

const LoaderOverlay = (props) => {
  let display = 'none';
  props.isDisplayed ? (display = 'block') : (display = 'none');

  const content = (
    <div className="ddc-loader" style={{ display: display }}>
      <div className="ddc-loader-content"></div>
    </div>
  );

  return ReactDom.createPortal(content, document.getElementById('loader-hook'));
};

const Loader = (props) => {
  return <LoaderOverlay {...props}>{props.children}</LoaderOverlay>;
};

Loader.propTypes = {
  isDisplayed: PropTypes.bool,
};

Loader.defaultProps = {
  isDisplayed: false,
};

export default Loader;
