import React from 'react';

import { useForm } from '../../../shared/hooks/form.hook';
import { VALIDATOR_REQUIRE } from '../../../shared/utils/validators';

import Button from '../../../shared/components/ui-elements/button/button';
import Input from '../../../shared/components/ui-elements/input/input';

import './type-asset-form.css';

const TypeAssetForm = (props) => {
  const initialState = {
    name: {
      value: props.typeAsset.name,
      isValid: false,
    },
    description: {
      value: props.typeAsset.description,
      isValid: true,
    }
  };

  const [formState, inputHandler] = useForm(initialState, false);

  const saveTypeAsset = async (event) => {
    event.preventDefault();
    const typeAsset = props.typeAsset;
    typeAsset.name = formState.inputs.name.value;
    typeAsset.description = formState.inputs.description.value;
    props.onFormSave(typeAsset);
  };

  return (
    <form
      className={`subcategory-form ${props.className}`}
      onSubmit={saveTypeAsset}>
      <Input
        id="name"
        className="mb-3"
        label="Name"
        value={props.typeAsset && props.typeAsset.name ? props.typeAsset.name : ''}
        validators={[VALIDATOR_REQUIRE()]}
        onInputChanged={inputHandler}/>
      <Input
        id="description"
        className="mb-3"
        label="Description"
        type="textarea"
        value={
          props.typeAsset && props.typeAsset.description && props.typeAsset.description
        }
        validators={[]}
        onInputChanged={inputHandler}/>
      <Button
        className="ddc-bim-blue ddc-w-100"
        type="submit"
        data-bs-dismiss="modal"
        disabled={!formState.isFormValid}>
        Save
      </Button>
    </form>
  );
};

export default TypeAssetForm;
