import React from "react";
import { Box, Stack } from "@mui/material";
import IMAGES from "../../../assets/imgs/images";

import "./footer-v2.css";

const Footer = (props) => {
  return (
    <footer className="ddc-footer bg-light">
      <Stack alignItems="center" justifyContent="center" sx={{ p: 3 }}>
        <Box
          rowGap={10}
          columnGap={10}
          alignItems="center"
          justifyContent="center"
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(2, 1fr)",
            sm: "repeat(6, 1fr)",
          }}
        >
          <img
            className="ddc-footer-image"
            src={IMAGES.logoFonds}
            alt="Fonds de compensation"
          />
          <img
            className="ddc-footer-image"
            src={IMAGES.logoBaatz}
            alt="Fonds de compensation"
          />
          <img
            className="ddc-footer-image-schroeder"
            src="/schroeder-logo.svg"
            alt="Fonds de compensation"
          />
          <img
            className="ddc-footer-image"
            src={IMAGES.logoBlack}
            alt="Fonds de compensation"
          />

          <img
            className="ddc-footer-image"
            src={IMAGES.logoEntrapaulus}
            alt="Fonds de compensation"
          />
          <img
            className="ddc-footer-image"
            src="/LIST-logo.svg"
            alt="Fonds de compensation"
          />
        </Box>
      </Stack>
    </footer>
  );
};

export default Footer;
