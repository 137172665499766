import React, { Fragment, useState, useRef, useContext} from 'react';

import { useForm } from '../../../hooks/form.hook';
import { useMessage } from '../../../hooks/message.hook';
import { VALIDATOR_EMAIL } from '../../../utils/validators';
import { doSignIn } from '../../../../services/auth.service';
import { AuthContext } from '../../../../shared/context/auth.context';

import Button from '../button/button';
import Input from '../input/input';
import Loader from '../loader/loader';
import Toast from '../toast/toast';

import './login.css';

const Login = (props) => {
  // Initial values
  const defaultState = {
    email: {
      value: '',
      isValid: false,
    },
    password: {
      value: '',
      isValid: true,
    },
  };
  // Manage Auth credentials
  const auth = useContext(AuthContext);
  // References to Form child components
  const emailRef = useRef();
  const passwordRef = useRef();
  // Loader
  const [loading, isLoading] = useState(false);
  // Message
  const [message, show, showMessage] = useMessage();
  // Login Form
  const [formState, inputChange, resetForm] = useForm(defaultState);

  const doLogin = async (event) => {
    event.preventDefault();
    isLoading(true);
    try {
      const formData = new FormData(event.target);
      formData.append('email', formState.inputs.email.value);
      formData.append('password', formState.inputs.password.value);
      console.log('data', formData);

      // Parse FormData to JSON
      const data = {};
      formData.forEach((value, key) => (data[key] = value));

      const response = await doSignIn(data);
      if (response.status === 201) {
        const responseData = await response.json();
        // Add credentials to memory
        auth.login(responseData.user.id, responseData.token);
        // Empty fields from form
        doReset();
      } else {
        const error = await response.json();
        showMessage(error.message);
      }
    } catch (err) {
      console.error(err);
    }
    isLoading(false);
  };

  const doReset = () => {
    // Reset Form Hook
    resetForm();
    if (auth.isLoggedIn) {
      // Reset Child Components
      emailRef.current.resetComponent();
      passwordRef.current.resetComponent();
    }
  };

  let element = (
    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id="loginMenu"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false">
        <i className="fas fa-sign-in-alt"></i> Login
      </a>
      <ul
        className="ddc-login-menu dropdown-menu dropdown-menu-end"
        aria-labelledby="loginMenu">
        <li>
          <form className="form-horizontal" onSubmit={doLogin}>
            <Input
              id="email"
              className="mb-3"
              placeholder="Email"
              type="email"
              name="email"
              validators={[VALIDATOR_EMAIL()]}
              onInputChanged={inputChange}
              ref={emailRef}/>
            <Input
              id="password"
              className="mb-3"
              placeholder="Password"
              type="password"
              name="password"
              validators={[]}
              onInputChanged={inputChange}
              ref={passwordRef}/>
            <Button
              className="ddc-w-100"
              type="submit"
              disabled={!formState.isFormValid}>
              Login
            </Button>
          </form>
        </li>
      </ul>
    </li>
  );
  if (auth.isLoggedIn) {
    element = (
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          id="logoutMenu"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          <i className="fa-solid fa-user-large"></i>
        </a>
        <ul
          className="ddc-login-menu dropdown-menu dropdown-menu-end"
          aria-labelledby="logoutMenu">
          <li>
              <Button
                className="ddc-w-100"
                type="button"
                onClick={auth.logout}>
                Logout
              </Button>
          </li>
        </ul>
      </li>
    );
  }

  

  return (
    <Fragment>
      {element}
      <Loader isDisplayed={loading} />
      <Toast isShow={show}>{message}</Toast>
    </Fragment>
  );
};

export default Login;
