import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import ReactPaginate from 'react-paginate';

import { Actions } from '../../../shared/constants/actions';

import List from '../../../shared/components/ui-elements/list/list';
import Button from '../../../shared/components/ui-elements/button/button';
import CheckBox from '../../../shared/components/ui-elements/checkbox/checkbox';
import ItemActions from '../../../shared/components/ui-elements/item-actions/item-actions';

import IMAGES from '../../../shared/assets/imgs/images';

import './poi-list.css';

const PoiList = (props) => {
  const {items, itemsPerPage} = props;

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const actionHandler = (action, poi) => {
    props.onAction && props.onAction(action, poi);
  };

  /**
   * This function will return to the callback the list of selected POIs
   */
  const updateHandler = () => {
    const selectedItems = items.filter((item) => item.isSelected);

    props.onAction &&
      props.onAction(
        Actions.UPDATE,
        selectedItems.map((item) => item.poi)
      );
  };

  const selectAllHandler = (isSelect) => {
    props.onSelectAll && props.onSelectAll(isSelect);
  };

  return (
    <div
      className={`ddc-inventory-list ${props.className || ''}`}
      style={{ height: '600px' }}>
      <h5>Assets</h5>
      <div className="row">
        <div className="col-md-3 align-middle d-grid gap-1 mb-1 d-flex justify-content-center align-items-center">
          <CheckBox
            id=""
            name="all"
            value="Select all"
            onClick={selectAllHandler}
          />
        </div>
        <div className="col-md-9 align-middle d-grid gap-1 mb-1">
          <Button className="ddc-bim-blue" onClick={updateHandler}>
            <i className="bi bi-save d-block mx-auto mb-1 text-center"></i>
            <small>Update</small>
          </Button>
        </div>
      </div>
      <List className="h-75 overflow-auto">
        {currentItems.map((item) => (
          <ItemActions
            key={item.id}
            item={item}
            onAction={actionHandler}
            actions={[Actions.SELECT]}
            selected={item.isSelected}>
            <div className="w-100 justify-content-between">
              <div>
                {(item.srcImages && item.srcImages.length > 0) && (
                  item.srcImages.map(image => (
                    <img key={uuidv4()} src={image} style={{ width: '100px', padding: '4px' }} />
                  ))
                  // <Mosaic cols={3} items={item.srcImages} />
                )}
                {(!item.srcImages || item.srcImages.length === 0) && (
                  <img
                    src={IMAGES.defaultImage}
                    style={{ width: '100%' }}
                    alt="default image"
                  />
                )}
              </div>
              <p style={{ textAlign: 'center' }}>{item.title}</p>
            </div>
            {/* <small className="text-muted">{item.label}</small> */}
          </ItemActions>
        ))}
        <div className="ddc-paginator">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
          />
        </div>
      </List>
    </div>
  );
};

PoiList.propTypes = {
  items: PropTypes.array,
  onAction: PropTypes.func,
  onSelectAll: PropTypes.func,
  itemsPerPage: PropTypes.number
};

PoiList.defaultProps = {
  items: [],
  itemsPerPage: 10
};

export default PoiList;
