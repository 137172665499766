import React from 'react';
import Tab from '../../shared/components/ui-elements/tab/tab';
import BookingList from '../../booking/container/booking';
import Inventory from '../../inventory/container/inventory';
import TypeAsset from '../../type-asset/container/type-asset';
import Uploader from '../../uploader/container/uploader';

import './admin.css';

const Admin = (props) => {
  const tabs = [
    {
      id: 'booking',
      title: 'Booking',
      content: <BookingList />,
    },
    {
      id: 'type-assets',
      title: 'Type of Assets',
      content: <TypeAsset />,
    },
    {
      id: 'uploader',
      title: 'Uploader',
      content: <Uploader />,
    },
    {
      id: 'inventory',
      title: 'Inventory',
      content: <Inventory />,
    },
  ];

  return <Tab tabs={tabs}></Tab>;
};

export default Admin;
