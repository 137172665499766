import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import './table.css';

/**
 *
 * @param {*} props List of properties:
 *      - **id**: unique ID each cell has. Cannot provided
 *      - **col**: index to identify which position in a object takes this value
 *      - **row**: index to determinate which object is updated
 *      - **cellValue**: initial value the cell takes
 *      - **onChangeValue(value, col, row)**: event to reflect changes in the cell
 * @returns
 */
const Cell = (props) => {
  const { id, col, row, isEditable, onChangeValue } = props;
  const [value, setValue] = useState(props.cellValue);
  const blurHandler = (event) => {
    const newValue = event.target.innerText.trim();
    // value changed
    if (value !== newValue) {
      setValue(newValue);
      onChangeValue(newValue, col, row);
    }
  };

  return (
    <td
      className={`bimy-table-cell ${props.className}`}
      key={id && id}
      suppressContentEditableWarning={isEditable}
      contentEditable={isEditable}
      onBlur={blurHandler}
    >
      {value}
    </td>
  );
};

/**
 *
 * @param {*} props List of properties:
 * - columns
 * - data
 * - isEditable
 * - editColumns: the columns that can be edited
 * - addRemoveItem
 * - onChangeValue
 *
 * @returns
 */
const Table = (props) => {
  const [dataRows, setDataRows] = useState();
  const { columns, isEditable, addRemoveItem, data, editColumns } = props;

  useEffect(() => {
    setDataRows(data);
  }, [data]);

  /**
   *
   * @param {*} event
   * @returns
   */
  const addItemHandler = (event) => {
    let length = 0;
    // If Headers are set, then set a new array item [] with the length of Headers
    if (columns && columns.length > 0) length = columns.length;
    // If not Headers, then refer to data.
    // Consider there is at least one item and its length is bigger than 0
    else if (dataRows && dataRows.length > 0 && dataRows[0].length > 0)
      length = dataRows[0].length;

    // Cannot build the new row
    if (length === 0) return;

    const item = [...Array(length)].map((x) => '');
    const newData = [...dataRows, item];
    setDataRows([...dataRows, item]);
  };

  let colSpan = 1;
  colSpan =
    (!columns || (columns && columns.length === 0 && props.data.length > 0)) &&
    props.data[0].length + 1;

  /**
   * Needs to consider colSpan if it is editable but there is not headers
   */
  const addItem = (
    <th className="bimy-text-right bimy-px-sm" colSpan={colSpan}>
      <span className="bimy-table-item-add">
        <i className="fa-solid fa-plus" onClick={addItemHandler}></i>
      </span>
    </th>
  );

  /**
   *
   * @param {*} event
   */
  const removeItemHandler = (event) => {
    const index = parseInt(event.target.getAttribute('row'));
    const newData = [...dataRows];
    newData.splice(index, 1);
    setDataRows(newData);
  };

  const removeItem = (index) => {
    return (
      <td className="bimy-text-right bimy-px-sm">
        <span className="bimy-table-item-remove">
          <i
            className="fa-solid fa-minus"
            onClick={removeItemHandler}
            row={index}
          ></i>
        </span>
      </td>
    );
  };

  const cellChangeHandler = (value, col, row) => {
    const newData = [...dataRows];
    newData[row][col] = value;
    setDataRows(newData);
    props.onChangeValue && props.onChangeValue(newData);
  };

  let header;

  columns &&
    columns.length > 0 &&
    (header = (
      <thead>
        <tr>
          {columns.map((column) => {
            return <th key={uuidv4()}>{column}</th>;
          })}
          {addRemoveItem && addItem}
        </tr>
      </thead>
    ));

  columns &&
    columns.length === 0 &&
    (header = (
      <thead>
        <tr>{addRemoveItem && addItem}</tr>
      </thead>
    ));

  // No header but add new items
  !columns &&
    addRemoveItem &&
    (header = (
      <thead>
        <tr>{addRemoveItem && addItem}</tr>
      </thead>
    ));

  let body;
  dataRows &&
    (body = (
      <tbody>
        {dataRows.map((item, row) => {
          return (
            <tr className="bimy-table-row" key={uuidv4()}>
              {item.map((cell, col) => {
                return (
                  <Cell
                    key={uuidv4()}
                    row={row}
                    col={col}
                    isEditable={isEditable || editColumns.includes(col)}
                    onChangeValue={cellChangeHandler}
                    cellValue={cell}
                  />
                );
              })}
              {addRemoveItem && removeItem(row)}
            </tr>
          );
        })}
      </tbody>
    ));

  let content = (
    <table className="bimy-table bimy-table-stripe">
      {header}
      {body}
    </table>
  );

  (!props.columns || props.columns.length === 0) &&
    (!props.data || props.data.length === 0) &&
    (content = <p>No data provided.</p>);

  return content;
};

Table.propsType = {
  columns: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.any),
  isEditable: PropTypes.bool,
  editColumns: PropTypes.arrayOf(PropTypes.number),
  addRemoveItem: PropTypes.bool,
  onChangeValue: PropTypes.func,
};

Table.defaultProps = {
  isEditable: false,
  editColumns: [],
  data: [],
  columns: [],
};

export default Table;
