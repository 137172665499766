import React, { useEffect } from 'react';

const ivrRoot = 'https://bim-y.iv.navvis.com/?site='

const IvionView = (props) => {
  const { siteId, onPoiClick } = props;
  const endpoint = `${ivrRoot}`;
  useEffect(() => {
    window.addEventListener("message", handleMessage);
    sendBimySiteInstance(endpoint, siteId);
  }, []);

  //send sendBimySiteInstance to navvis module
  const sendBimySiteInstance = (url, siteId) => {
    const iframe = document.getElementById('navvis-ivion-iframe');
    iframe.onload = function () {
      iframe.contentWindow.postMessage({ type: 'URL', endpoint: url, siteId: siteId }, '*');
    }
  };

  const handleMessage = (event) => {
    //get poi information onClick
    if (event.data.type === 'GET_POI') {
      // console.log('poi reçu du module navvis après un click : ', event.data)
      onPoiClick(event.data.pois)
    }
  }


  return (
    <>
      <iframe
        id="navvis-ivion-iframe"
        src='/module-navvis'
        title='Navvis Ivion Indoor'
        width='100%'
        height='100%'
      ></iframe>
    </>
  )
}

export default IvionView;