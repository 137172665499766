import React, { useEffect, useState, useCallback, useContext } from 'react';

import Toast from '../../shared/components/ui-elements/toast/toast';
import Button from '../../shared/components/ui-elements/button/button';
import Loader from '../../shared/components/ui-elements/loader/loader';
import Select from '../../shared/components/form-elements/select/select';
import FilePicker from '../../shared/components/ui-elements/file-picker/file-picker';

import { useForm } from '../../shared/hooks/form.hook';
import { useHttpClient } from '../../shared/hooks/http.hook';
import { useMessage } from '../../shared/hooks/message.hook';
import { AuthContext } from '../../shared/context/auth.context';

import { VALIDATOR_NOT_UNDEFINED } from '../../shared/utils/validators';

import { addPOIs, uploadPOIs } from '../../services/iv.service';
import { getAllInstances } from '../../services/instance.service';

import './uploader.css';

const initValues = {
  projects: [],
};

const defaultState = {
  pois: {
    value: null,
    isValid: false,
  }
};

const Uploader = (props) => {
  const [data, setData] = useState(initValues);
  const auth = useContext(AuthContext);

  const [formState, inputChange, resetForm, loadState] = useForm(defaultState);
  const [isLoading, error, sendRequest, clearError] = useHttpClient();
  const [message, show, showMessage] = useMessage();

  const filePickerHandler = useCallback((id, files, isValid) => {
    // If not file found, then exit
    if (!files[0]) return;

    inputChange(id, files[0], true);

  }, []);

  const uploadPoisHandler = async (event) => {
    event.preventDefault();
    const data = {
      poisZip: formState.inputs.pois.value,
    };

    try {
      const response = await uploadPOIs(data.projectId, data.poisZip, auth.token, sendRequest);
      resetForm();
      showMessage('POIs imported!');
    } catch (error) {
      console.error(error);
      showMessage(error.message);
    }
  };

  const content = (
    <form onSubmit={uploadPoisHandler}>
      <FilePicker
        id={'pois'}
        label={'Upload POI package'}
        validators={[]}
        accept={'.poix'}
        filesPickerCB={filePickerHandler}
      />
      {formState.inputs.pois.value ? (
        <div className="ddc-uploader-file-info">
          <span>{formState.inputs.pois.value.name}</span>
        </div>
      ) : (
        <div className="ddc-uploader-not-found">
          <span>No package found</span>
        </div>
      )}
      <div className="ddc-uploader-actions">
        <Button
          className={'ddc-btn-blue ddc-btn-gap'}
          type={'submit'}
          disabled={!formState.isFormValid}>
          <i className="fa-regular fa-floppy-disk"></i>
          <span>Load POIs</span>
        </Button>
      </div>
    </form>
  );

  return (
    <div className={'ddc-uploader'}>
      {content}
      <Loader isDisplayed={isLoading} />
      <Toast isShow={show}>{message}</Toast>
    </div>
  );
};

export default Uploader;
