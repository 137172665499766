import React from 'react';
import PropTypes from 'prop-types';
import QRCodeReact from 'qrcode.react';

import './qr-code.css';

const QRCode = (props) => {

  return <QRCodeReact value={props.value} size={70} />;
};

QRCode.propTypes = {
  value: PropTypes.string.isRequired,
};

export default QRCode;
