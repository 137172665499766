/**
 * List of actions available for authentication
 */
export const Actions = Object.freeze({
  BOOKED: 'booked',
  CLOSED: 'closed',
  CANCELLED: 'cancelled',
  DELETE: 'delete',
  UPDATE: 'update',
  VIEW: 'view',
  ADD: 'add',
  REMOVE: 'remove',
  SELECT: 'select',
  SELECT_ALL: 'select all',
  EDIT: 'edit',
});
