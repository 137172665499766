import { request } from './http-client.service';

const PATH_BOOKING = '/booking';

export function bookPoi(data, cbRequest) {
  const endPoint = `${PATH_BOOKING}`;
  return cbRequest(endPoint, 'POST', JSON.stringify(data), {
    'Content-Type': 'application/json'
  });
}

export function getBookings() {
  return request(`${PATH_BOOKING}`, 'GET', null, {
    'Content-Type': 'application/json'
  });
}

export function findBookingByPoi(instanceId, poiId) {
  return request(`${PATH_BOOKING}/instance/${instanceId}/poi/${poiId}`, 'GET', null, {
    'Content-Type': 'application/json'
  });
}

export function findBookingByPoiCB(instanceId, poiId, cbRequest) {
  return cbRequest(`${PATH_BOOKING}/instance/${instanceId}/poi/${poiId}`, 'GET', null, {
    'Content-Type': 'application/json'
  });
}

export function updateBooking(booking) {
  return request(`${PATH_BOOKING}/${booking.id}`, 'PUT', JSON.stringify(booking), {
    'Content-Type': 'application/json'
  });
}