import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useMessage } from '../../shared/hooks/message.hook';
import { useHttpClient } from '../../shared/hooks/http.hook';
import { AuthContext } from '../../shared/context/auth.context';

import { Actions } from '../../shared/constants/actions';
import { LevelStatus } from '../../shared/constants/LevelStatus';

import { VALIDATOR_NOT_UNDEFINED } from '../../shared/utils/validators';

import PoiList from '../components/poi-list/poi-list';
import Loader from '../../shared/components/ui-elements/loader/loader';
import Toast from '../../shared/components/ui-elements/toast/toast';
import Select from '../../shared/components/form-elements/select/select';
import Modal from '../../shared/components/ui-elements/modal/modal';
import MainContent from '../../shared/components/ui-elements/main-content/main-content';
import TypeAssetList from '../../type-asset/components/type-asset-list/type-asset-list';

import { getInstances } from '../../services/instance.service';
import { getAllPoisTypes, getPois, populateData } from '../../services/iv.service';
import {
  getTypeAssetsByType,
} from '../../services/type-asset.service';

import './inventory.css';

const { REACT_APP_DDC_URI_DATA } = process.env;

const Inventory = (props) => {
  const [loading, setIsLoading] = useState(false);
  const [instances, setInstances] = useState([]);
  const [instance, setInstance] = useState();
  const [types, setTypes] = useState([]);
  const [type, setType] = useState();
  const [typeAssets, setTypeAssets] = useState([]);
  const [typeAsset, setTypeAsset] = useState();
  const [pois, setPois] = useState([]);

  const [message, show, showMessage, level] = useMessage();
  const [isLoading, error, sendRequest, clearError] = useHttpClient();
  const auth = useContext(AuthContext);

  // Manage Modal
  const [isModal, setIsModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const loadInstances = async () => {
    setInstance();
    try {
      const response = await getInstances();

      if (response.status === 201) {
        const responseData = await response.json();
        setInstances(responseData);
      } else {
        console.log('getting instances failed!');
        const error = await response.json();
        throw error;
      }
    } catch (error) {
      throw error;
    }
  };

  /**
   * 
   * @param {*} typeAssetId 
   * @param {*} poiIds 
   * @returns 
   */
   const doPopulateData = async (typeAssetId, poiIds = []) => {
    try {
      await populateData(instance.id, typeAssetId, poiIds, auth.token, sendRequest);
      showMessage('populate done!')
      // Reload POIs
      loadPOIs();
    } catch (error) {
      showMessage(error.message);
    }
  };

  const loadPOIs = async () => {
    try {
      let filter = {};
      type && (filter = {
        type: type.id,
        isAssetTypeString: false
      });
      const pois = await getPois(instance.id, filter, sendRequest);
      let customPois = Array.from(pois, (poi) => {
        let customData = {};
        try {
          customData = JSON.parse(poi.custom_data);
        } catch (error) {
          console.error(error);
          showMessage(error.message);
        }
        const cropsFilenames = (customData && customData.crops) ? customData.crops : [];
        let pathImages = cropsFilenames.map(crop => '/data/inventory/'.concat(crop.filename));

        return {
          id: poi.id,
          title: Object.values(poi.titles)[0],
          label: 'myLabel',
          isSelected: false,
          srcImages: pathImages,
          poi
        };
      });

      setPois(customPois);
    } catch (error) {
      setPois([]);
      console.error(error);
      showMessage(error.message);
    }
  };

  const loadTypeAssets = async () => {
    setTypeAsset();
    setIsLoading(true);
    if (!instance || !type) {
      setIsLoading(false);
      return;
    }
    try {
      const response = await getTypeAssetsByType(instance.id, type.id);

      if (response.status === 201) {
        const responseData = await response.json();
        setTypeAssets(responseData);
      } else if (response.status === 204) {
        // Not found | HTTP Error code: 204
        setTypeAssets([]);
        showMessage(`No Type Assets found for type: ${type.title}`);
      } else {
        setTypeAssets([]);
        const error = await response.json();
        showMessage(error.message);
      }
    } catch (error) {
      showMessage(error.message);
    }
    setIsLoading(false);
  };

  const loadTypes = async () => {
    setIsLoading(true);

    // Clear any previous state
    setType();
    setTypeAsset();
    if (!instance) {
      setIsLoading(false);
      return;
    }
    try {
      const response = await getAllPoisTypes(instance.id);
      if (response.status === 201) {
        const responseData = await response.json();
        // Add property title to each type to be used on Select component
        const customTypes = responseData.map((item) => ({
          ...item,
          title: item.name['en'] || item.name[Object.keys(item.name)[0]],
        }));
        setTypes(customTypes);
      } else {
        setTypes();
        const error = await response.json();
        showMessage(error.message);
      }
    } catch (error) {
      showMessage(error.message);
    }
    setIsLoading(false);
  };

  const showModal = () => {
    setIsModal(true);
  };

  const hideModal = () => {
    setModalContent('');
    setIsModal(false);
  };

  /**
   * 
   * @param {*} action 
   * @param {*} poi can be one or many POIs
   * @returns 
   */
  const poiActionHandler = (action, poi) => {
    switch (action) {
      case Actions.SELECT:
        const updatedPois = pois.map((p) =>
          p.id === poi.id ? { ...p, isSelected: !p.isSelected } : p
        );
        setPois(updatedPois);
        break;
      case Actions.UPDATE:
        if (!typeAsset) {
          showMessage('No Type Asset selected. Please, select one.');
          return;
        }
        if (!poi.length) {
          showMessage('No POIs selected. Please, select one.');
          return;
        }
        doPopulateData(typeAsset.id, poi.map(p => p.id));
        break;
      default:
        break;
    }
  };
  const selectPoisHandler = (isSelected) => {
    let updatedPois = [];

    updatedPois = pois.map((p) => ({ ...p, isSelected: isSelected }));
    setPois(updatedPois);
  };

  const typeAssetActionHandler = (action, typeAsset) => {
    switch (action) {
      case Actions.VIEW:
        setTypeAsset(typeAsset);
        break;
      default:
        break;
    }
  };

  const initLoader = () => {
    setIsLoading(true);
    try {
      loadInstances();
    } catch (error) {
      showMessage(error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    initLoader();
  }, []);

  useEffect(() => {
    // if (instance && type && typeAsset) {
    //   loadTypeAssetValues();
    //   return;
    // }

    // Only if instance and type exist, it can load the Type of Assets & POIs
    if (instance && type) {
      loadTypeAssets();
      loadPOIs();
      return;
    }
    // When the instance exists and changes, load the types
    if (instance) {
      loadTypes();
    }

    if (!instance) {
      setType();
      setTypeAsset();
    }
  // }, [instance, type, typeAsset]);
  }, [instance, type]);

  const instanceHandler = useCallback((id, value, isValid) => {
    setTypeAsset();
    setType();
    setInstance(value);
  }, []);

  const typeHandler = useCallback((id, value, isValid) => {
    setTypeAsset();
    setType(value);
  }, []);

  const instanceSelect = (
    <Select
      id="instance"
      onChange={instanceHandler}
      items={instances}
      label="Select an instance:"
      validators={[VALIDATOR_NOT_UNDEFINED()]}
    />
  );

  console.log('dfd', !pois.length)

  const typeSelect = (
    <Select
      id="type"
      onChange={typeHandler}
      items={types ? types : []}
      label="Select a Type:"
      validators={[VALIDATOR_NOT_UNDEFINED()]}
    />
  );

  return (
    <MainContent>
      <div className="ddc-inventory h-100">
        <div className="ddc-type-asset-selector row">
          {instanceSelect}
          {instance && typeSelect}
        </div>
        <div className="ddc-inventory-viewer row mt-2">
          <div className="ddc-pn-type-assets col-12 col-md-6 my-1">
            {type && (
              <TypeAssetList
                className="p-3 border bg-light"
                items={typeAssets}
                canAdd={false}
                onAction={typeAssetActionHandler}
              />
            )}
          </div>
          <div className="ddc-pn-assets col-12 col-md-6 my-1">
            {type && (
              <PoiList
                className="p-3 border bg-light"
                items={pois}
                onAction={poiActionHandler}
                onSelectAll={selectPoisHandler}
              />
            )}
          </div>
        </div>
      </div>

      <Modal display={isModal} onClose={hideModal} title="">
        {modalContent}
      </Modal>
      <Loader isDisplayed={isLoading} />
      <Toast isShow={show} level={level}>{message}</Toast>
    </MainContent>
  );
};

export default Inventory;
