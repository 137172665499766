import { React } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import BuildingCard from '../building-card/building-card';

import './building-list.css';

const BuildingList = (props) => {
  const { items, onConfirm } = props;
  const element = (
    <div className="ddc-building-list">
      {items.length > 0 &&
        items.map((building) => <BuildingCard key={uuidv4()} item={building} />)}
    </div>
  );

  return element;
};

BuildingList.propTypes = {
  items: PropTypes.array,
  onConfirm: PropTypes.func,
};

BuildingList.defaultProps = {
  items: [],
};

export default BuildingList;
