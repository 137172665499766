import React from 'react';

import ddcLogo from '../../../assets/imgs/DDC_logo.png';
import bimyLogo from '../../../assets/imgs/BIM-Y_logo_color.png';

import './footer.css';

const Footer = (props) => {
  return (
    <footer className="ddc-footer bg-light">
      <div className="container bg-light">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h6>About</h6>
            <p className="text-justify">
              The BIM model play its full role for new constructions but is very
              expensive to implement it for existing buildings.
            </p>
            <p className="text-justify">
              {' '}
              By using 3D point clouds technology, BIM-Y offer an affordable
              digital twin solution for your building..
            </p>
          </div>
          <div className="col-sm-12 col-md-3 text-center">
            <h6>Quick Links</h6>
            <ul className="ddc-footer-links">
              <li>
                <a href="https://www.bim-y.com/what-we-do">About Us</a>
              </li>
              <li>
                <a href="https://www.bim-y.com/contact">Contact Us</a>
              </li>
              <li>
                <a href="https://www.bim-y.com/what-we-do">Contribute</a>
              </li>
            </ul>
          </div>
          <div className="ddc-bimy-logo col-6 col-md-1 d-flex align-items-center">
            <a href="https://www.bim-y.com/">
              <img
                src={bimyLogo}
                alt="BIM-Y Logo"
                style={{width: '100%'}}
              />
            </a>
          </div>
          <div className="ddc-logo col-6 col-md-2 d-flex align-items-center">
            <a href="https://www.nweurope.eu/projects/project-search/digital-deconstruction/">
              <img
                src={ddcLogo}
                alt="DDC Logo"
                style={{width: '100%'}}
              />
            </a>
          </div>
        </div>
        <hr />
      </div>
      <div className="container bg-light">
        <div className="row">
          <div className="col-md-7 col-sm-6 col-xs-12">
            <p className="copyright-text">
              Copyright &copy; 2022 All Rights Reserved by <a href="https://www.bim-y.com">Bim-y</a>.
            </p>
          </div>

          <div className="col-md-5 col-sm-6 col-xs-12">
            <ul className="ddc-social-icons">
              <li>
                <a className="facebook" href="https://www.facebook.com/BIM-Y-103827211303861">
                  <i className="fa-brands fa-facebook"></i>
                </a>
              </li>
              <li>
                <a className="instagram" href="https://www.instagram.com/teambimy/">
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </li>
              <li>
                <a className="youtube" href="https://www.youtube.com/channel/UCJ4eNMpfDiCPflJf6vYHuow">
                  <i className="fa-brands fa-youtube"></i>
                </a>
              </li>
              <li>
                <a className="linkedin" href="https://www.linkedin.com/company/18517451">
                  <i className="fa-brands fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a className="vimeo" href="https://vimeo.com/cypresinternational">
                  <i className="fa-brands fa-vimeo"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
