import React, { Fragment, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';

import { validate } from '../../../utils/validators';

import './select.css';

const Actions = Object.freeze({
  CHANGED: 'CHANGED',
  INITIALISE: 'INITIALISE',
});

const inputReducer = (state, action) => {
  switch (action.type) {
    case Actions.CHANGED:
    case Actions.INITIALISE:
      return {
        ...state,
        value: action.newValue,
        isValid: validate(action.newValue, action.validators),
      };
    default:
      // Current data for the input
      return state;
  }
};

const Select = (props) => {

  const initialState = {
    value: props.defaultValue && props.defaultValue,
    isValid: props.defaultValue ? true : false,
  };

  const [inputState, dispatch] = useReducer(inputReducer, initialState);

  const { id, items, defaultValue, label } = props;
  const { value, isValid } = inputState;

  /**
   *
   * @param {*} event
   */
  const changeHandler = (event) => doChange(event.target.value);

  const doChange = (value) => {
    const selection = items.find((i) => i.id == value);

    dispatch({
      type: Actions.CHANGED,
      newValue: selection,
      validators: props.validators,
    });
  }

  useEffect(() => {
    props.onChange && props.onChange(id, value, isValid);
  }, [value, isValid]);

  useEffect(() => {
    props.defaultValue && doChange(props.defaultValue);
  }, [props.defaultValue]);

  let element = (
    <select
      className="form-select"
      style={{
        width: '100%', maxWidth: '100%', fontSize: 15, display: 'flex', background: 'transparent',
        alignItems: 'left', '& > :not(style)': { m: 1 }
      }}
      id={id}
      name={id}
      disabled={props.disabled}
      aria-label="select"
      onChange={changeHandler}
      value={defaultValue && defaultValue.id}>
      {!defaultValue && <option defaultValue>Please, select one</option>}
      {items.map((item) => (
        <option
          key={item.id}
          value={item.id}>
          {item.title}
        </option>
      ))}
    </select>
  );

  return (
    <div className={`ddc-select ${props.className || ''}`}>
      {label && (
        <Fragment>
          <label htmlFor={id}>{label}</label>
          <br />
        </Fragment>
      )}

      {element}
    </div>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
};

Select.defaultProps = {
  validators: [],
  disabled: false
};

export default Select;
