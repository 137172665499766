import { request } from './http-client.service';

const PATH_IV = '/iv';

export function getAllPoiTypeGroups(instanceId) {
  const endpoint = `${PATH_IV}/${instanceId}/poi_type_groups`;
  return request(endpoint, 'GET', null, {
    'Content-Type': 'application/json',
  });
}

export function getAllPoisTypes(instanceId) {
  const endpoint = `${PATH_IV}/${instanceId}/poi_types`;
  return request(endpoint, 'GET', null, {
    'Content-Type': 'application/json',
  });
}

export function getPoiType(instanceId, poiTypeId) {
  const endpoint = `${PATH_IV}/${instanceId}/poi_types/${poiTypeId}`;
  return request(endpoint, 'GET', null, {
    'Content-Type': 'application/json',
  });
}

export function saveDataPOI(instanceId, poiId, data) {
  const endpoint = `${PATH_IV}/${instanceId}/poi/${poiId}`;
  return request(endpoint, 'PUT', JSON.stringify(data), {
    'Content-Type': 'application/json',
  });
}

export function createPOI(instanceId, data) {
  const endpoint = `${PATH_IV}/${instanceId}/new-poi`;
  return request(endpoint, 'POST', JSON.stringify(data), {
    'Content-Type': 'application/json',
  });
}

/**
 * 
 * @param {*} instanceId 
 * @param {*} cbRequest 
 * @returns 
 */
export function getPoisTypes(instanceId, cbRequest) {
  const endpoint = `${PATH_IV}/${instanceId}/poi_types`;
  return cbRequest(endpoint, 'GET', null , {
    'Content-Type': 'application/json'
  });
}

/**
 * @deprecated Not longer import a JSON file.
 * 
 * Instead, using a compress file which loads specific data.
 * See {@link https://bim-y.atlassian.net/wiki/spaces/BYA/pages/495583235/PoI+Inventory+Package+file Poi Package}
 * 
 * @param {*} data 
 * @param {*} token 
 * @param {*} cbRequest 
 * @returns 
 */
export function addPOIs(data, token, cbRequest) {
  const endpoint = `${PATH_IV}/pois`;
  return cbRequest(endpoint, 'POST', JSON.stringify(data), {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  });
}

/**
 * This function will request to push a ZIP file with all the POIS and their images. 
 * 
 * @param {*} projectId Project's ID
 * @param {*} zipfile See {@link https://bim-y.atlassian.net/wiki/spaces/BYA/pages/495583235/PoI+Inventory+Package+file Poi Package}
 * @param {*} token 
 * @param {*} cbRequest 
 * @returns 
 */
export function uploadPOIs(projectId, zipfile, token, cbRequest) {
  const endpoint = `${PATH_IV}/uploadpois`;
  const formData = new FormData();
  // formData.append('projectId', projectId);
  formData.append('zipfile', JSON.stringify(zipfile));
  formData.append('files', zipfile);
  return cbRequest(endpoint, 'POST', formData, {
    Authorization: 'Bearer ' + token,
    keepalive: true
  });
}

export function getPoi(instanceId, poiId, cbRequest) {
  const endpoint = `${PATH_IV}/${instanceId}/poi/${poiId}`;
  return cbRequest(endpoint, 'GET', null, {
    'Content-Type': 'application/json'
  });
}

/**
 * Available filters: type, description, title
 * 
 * @param {*} instanceId 
 * @param {*} filter 
 * @param {*} cbRequest 
 * @returns 
 */
export function getPois(instanceId, filter = {}, cbRequest) {
  const searchParams = new URLSearchParams(filter).toString();
  const endpoint = `${PATH_IV}/${instanceId}/pois?${searchParams}`;
  return cbRequest(endpoint, 'GET', null, {
    'Content-Type': 'application/json'
  });
}

/**
 * This method gets POIs by filters.
 * 
 * Some of the filters to consider:
 * - datasetIds: []
 * - limit: 100
 * - offset: 100
 * - radius:
 * - siteModelEntityIds: []
 * - sortBy
 * - sortOrder
 * - sort_order: DESC
 * - step
 * - x
 * - y
 * - z
 * - xMax
 * - xMin
 * - yMax
 * - yMin
 * - site_model_entity: []
 * - lon_min: 5.98283924730682
 * - lon_max: 5.984299419489778
 * - lat_min: 50.88641351422949
 * - lat_max: 50.88729669316974
 * - limit: 100
 * - sort_by: IMPORTANCE
 * - poi_type: []
 * 
 * @param {*} instanceId 
 * @param {*} filter
 * @param {*} cbRequest 
 * @returns 
 */
export function getPoisByFilter(instanceId, filter = {}, cbRequest) {
  const endpoint = `${PATH_IV}/${instanceId}/pois/filter`;
  return cbRequest(endpoint, 'POST', JSON.stringify(filter), {
    'Content-Type': 'application/json'
  });
}

/**
 * 
 * @param {*} instanceId 
 * @param {*} filter 
 * @param {*} cbRequest 
 * @returns 
 */
export function populateData(instanceId, typeAssetId, poiIds = [], token, cbRequest) {
  const queryParams = new URLSearchParams({typeAssetId, poiIds}).toString();
  const endpoint = `${PATH_IV}/${instanceId}/populate_data?${queryParams}`;
  console.log(endpoint);
  return cbRequest(endpoint, 'PUT', null, {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  });
}