import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Actions } from "../../../shared/constants/actions";

// import Button from "../../../shared/components/ui-elements/button/button";
import List from "../../../shared/components/ui-elements/list/list";
import ItemActions from "../../../shared/components/ui-elements/item-actions/item-actions";
import TypeAssetForm from "../type-asset-form/type-asset-form";

import {
  Divider,
  Stack,
  Tooltip,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { upadateTypeAsset } from "../../../services/type-asset.service";

import "./type-asset-list.css";

const TypeAssetList = (props) => {
  const items = props.items;
  const [isModal, setIsModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const actionHandler = (action, subcategory) => {
    props.onAction(action, subcategory);
  };

  const doEditTypeAsset = async (typeAsset) => {
    props.isLoading(true);

    if (!typeAsset) {
      props.isLoading(false);
      return;
    }
    const response = await upadateTypeAsset(typeAsset);
    if (response.status === 201) {
      console.log("done", typeAsset);
      props.loadType();
      hideModal();
      setShowMessage(true);
    } else {
      console.error("Saving Type Asset failed!");
      const error = await response.json();
      console.error(error.message);
      hideModal();
    }
    props.isLoading(false);
  };

  const showModal = () => {
    setIsModal(true);
  };

  const hideModal = () => {
    setModalContent("");
    setIsModal(false);
  };
  const handleCloseMsg = () => {
    setShowMessage(false);
  };

  return (
    <div
      className={`ddc-type-asset-list ${props.className || ""}`}
      style={{ height: "100%" }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">Type of Assets</Typography>
        {props.canAdd && (
          <Button
            size="large"
            variant="contained"
            onClick={() => actionHandler(Actions.ADD)}
            startIcon={<AddCircleOutlineIcon />}
            sx={{ bgcolor: "#213765" }}
          >
            Add Standard Equipment
          </Button>
        )}
      </Stack>
      <List>
        <Stack direction="row" justifyContent="space-between" mt={3} mb={2}>
          <Typography variant="h5" color="#6d7c89">
            Name
          </Typography>
        </Stack>
        {items.map((item) => (
          <ItemActions
            key={item.id}
            item={item}
            onAction={actionHandler}
            actions={[Actions.VIEW]}
          >
            <Divider />
            <Stack direction="row" justifyContent="space-between" mt={2}>
              <Stack direction="column" sx={{ cursor: "pointer" }}>
                <Typography variant="h5" sx={{ mr: 70, fontWeight: "bold" }}>
                  {item.name}
                </Typography>
                <TextField
                  id="outlined-textarea"
                  value={item.description}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "transparent" },
                      "&:hover fieldset": { borderColor: "transparent" },
                      "&.Mui-focused fieldset": { borderColor: "transparent" },
                    },
                    minWidth: 600,
                  }}
                  multiline
                />
              </Stack>
              <Stack
                onClick={(e) => {
                  setModalContent(
                    <TypeAssetForm
                      className="modal-body"
                      typeAsset={item}
                      onFormSave={doEditTypeAsset}
                    />
                  );
                  showModal();
                }}
              >
                <Tooltip title="edit">
                  <EditIcon fontSize="large" color="#213765" />
                </Tooltip>
              </Stack>
            </Stack>
          </ItemActions>
        ))}
      </List>
      <Dialog onClose={hideModal} open={isModal}>
        <DialogTitle> Update type asset informations</DialogTitle>
        {modalContent}
      </Dialog>
      {showMessage && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={showMessage}
          onClose={handleCloseMsg}
          message="The type asset is updated"
        />
      )}
    </div>
  );
};

TypeAssetList.propTypes = {
  canAdd: PropTypes.bool,
};

TypeAssetList.defaultProps = {
  canAdd: true,
};

export default TypeAssetList;
