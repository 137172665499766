import React from 'react';
import PropTypes from 'prop-types';

import './label.css';

const Label = props => {
    return <span className={`ddc-label ${props.level ? props.level : 'general'}`}>{props.value}</span>;
}

Label.propsType = {
    value: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired
}

export default Label;