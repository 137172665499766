import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Stack, Toolbar } from '@mui/material';
import AccountPopover from './accountPopover';
import { Link as RouterLink } from 'react-router-dom';
import NavLinks from '../navigation/nav-links/nav-links';
import IMAGES from '../../../shared/assets/imgs/images';

//const NAV_WIDTH = '100%';
const NAV_WIDTH = '100%';
const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    [theme.breakpoints.up('lg')]: {
      minHeight: HEADER_DESKTOP,
      padding: theme.spacing(0, 8),
    },
  }));

  export default function Header() {
    return (
      <>
        <StyledToolbar>
          <RouterLink component={RouterLink} className="navbar-brand" to="/homepage">
            <img
              className="ddc-header-logo"
              src={IMAGES.logoBlack}
              alt="BIM-Y logo"
            />
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />
  
          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
          >
            <NavLinks />
            <AccountPopover />
          </Stack>
        </StyledToolbar>
      </>
    );
  }