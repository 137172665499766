import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { AuthContext } from '../../../context/auth.context';
import './nav-links.css';

const NavLinks = (props) => {
  const auth = useContext(AuthContext);

  return ( 
  <Fragment>
    <Link className="nav-link" to="/homepage">
    <Typography sx={{fontWeight: 'bold'}} variant="h5" color={'#213765'} display="block">Home</Typography>
    </Link>
    <Link className="nav-link" to="/building">
      <Typography sx={{fontWeight: 'bold'}} variant="h5" color={'#213765'}>Buildings</Typography>
    </Link>
    {auth.isLoggedIn && (
    <Link className="nav-link" to="/admin">
      <Typography sx={{fontWeight: 'bold'}} variant="h5" color={'#213765'}>Admin</Typography>
    </Link>
    )}
  </Fragment>);
};

export default NavLinks;
