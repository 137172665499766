import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './collapsible.css';

const Collapsible = (props) => {
  return (
      <Accordion defaultExpanded={props.isOpen}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            ":hover": {
              bgcolor: "#213765",
              color: "white",
            }
          }}
        >
          <Typography sx={{fontWeight: 600}} variant="h7" display="block" gutterBottom>{props.title}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, .03)'}} >
            {props.containe}
        </AccordionDetails>
      </Accordion>
  );
};

Collapsible.propTypes = {
  title: PropTypes.string.isRequired,
  containe: PropTypes.any,
};

Collapsible.defaultProps = {
    isOpen: true
}

export default Collapsible;
