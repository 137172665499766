import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BuildingList from '../components/building-list/building-list';
import Select from '../../shared/components/ui-elements/select/select';

import Loader from '../../shared/components/ui-elements/loader/loader';
import Toast from '../../shared/components/ui-elements/toast/toast';
import MainContent from '../../shared/components/ui-elements/main-content/main-content';

import { useMessage } from '../../shared/hooks/message.hook';

import { getInstances } from '../../services/instance.service';

import './building.css';

const Building = (props) => {
  const [instances, setInstances] = useState([]);
  // Loader
  const [loading, isLoading] = useState(false);
  // Message
  const [message, show, showMessage] = useMessage();

  let navigate = useNavigate();

  useEffect(() => {
    loadInstances();
  }, []);

  const loadInstances = async () => {
    isLoading(true);
    try {
      const response = await getInstances();
      if (response.status === 201) {
        const responseData = await response.json();
        setInstances(responseData);
      } else {
        console.log('getting buildings failed!');
        const error = await response.json();
        showMessage(error.message);
      }
      
    } catch (error) {
      showMessage(error.message);
    }
    isLoading(false);
  };

  /**
   *
   * @param {*} item
   */
  const confirmHandler = (item) => {
    navigate(`/viewer/${item.id}`);
  };

  // let element = <Select onConfirm={confirmHandler} items={instances} />;
  let element = <BuildingList onConfirm={confirmHandler} items={instances} />;

  return (
    <MainContent>
      <div className="ddc-building overflow-auto">
          {/* <p>You can find below the list of available sites to be picked.</p>
          <p>
            You can now explore them by selecting one of them and confirm the
            selection.
          </p> */}
          {element}
      </div>
      <Loader isDisplayed={loading} />
      {/* <Toast isShow={showToast}>{toastMessage}</Toast> */}
      <Toast isShow={show}>{message}</Toast>
    </MainContent>
  );
};

export default Building;
